import styled from 'styled-components/native';

export const Container = styled.View`
  width: 349px;
  height: 100px;
  background: ${props=>props.theme.colors.primary};
  padding: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 6px;
`;
