import styled from 'styled-components/native';
import { typeButton } from '../../button';

interface ITitle  {
  typeButton:typeButton
}
export const Title = styled.Text<ITitle>`
  font-family: ${props=>props.theme.fonts.regular};
  font-size: ${props=>props.theme.fontSize.label};
  color: ${props=>props.typeButton === 'PRIMARY' ? props.theme.colors.background :props.typeButton == 'ERROR'  ?props.theme.colors.background : props.theme.colors.primary };
`;
