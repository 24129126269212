import { MotiView } from 'moti';
import { Dimensions } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled(MotiView)`
    height: 100%;
    width:  100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    justify-content: center;
    align-items: center;
`;

export const ContainerBak = styled(MotiView)`
    height: ${Dimensions.get('window').height};
    width:  ${Dimensions.get('window').width};
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    justify-content: center;
    align-items: center;
`;

export const Content = styled(MotiView)`
    height: 246px;
    width: 349px;
    border-radius: 10px;
    background-color: ${props=>props.theme.colors.background};
    justify-content: center;
`;
