import styled from 'styled-components/native';

export const ContainerFooter = styled.View`
    height: 66px;
    width: 100%;
    background-color: #fff;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const FooterItem = styled.Pressable`
    width: 80px;
    margin-left: 10px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`
interface IFooterLabel{
    active:boolean
}
export const FooterLabel = styled.Text<IFooterLabel>`
  font-family: ${props=>props.theme.fonts.regular};
  font-size: ${props=>props.theme.fontSize.description};
  color: ${props=>props.theme.colors.primary};
  opacity: ${props=>props.active ? 1 : 0.4};
`