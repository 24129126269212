import { Feather } from "@expo/vector-icons";

interface IFooterItems {
    iconName: keyof typeof Feather.glyphMap
    label:string
    navigate:string
}
export const FooterItems:IFooterItems[] = [ {
    iconName:'home',
    label:'Início',
    navigate:'Home'
},{
    iconName:'file-text',
    label:'Histórico',
    navigate:'History'
},{
    iconName:'user',
    label:'Perfil',
    navigate:'Profile'
}]