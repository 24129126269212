import React, { useEffect, useState } from "react";
import { View,Image, ScrollView, Share, Alert } from "react-native";
import Header from "../../components/header";
import BigTitle from "../../components/titles/bigTitle";
import { GetImagesblobPaciente, GetImagesPacienteConsultas } from "../../services/tactisService/act";
import { AppAuth } from "../../store/app";
import { userAuth } from "../../store/auth";
import { ContainerRegister } from "../Auth/Register/styles";
import * as FileSystem from 'expo-file-system'
import { TouchableOpacity } from "react-native-gesture-handler";
import { Feather } from '@expo/vector-icons';
import * as Sharing from 'expo-sharing';
import * as MediaLibrary from 'expo-media-library';
export function AppointmentImages(){

  const {user,token} = userAuth()
  const {api,url} = AppAuth()

  // States
  const [imagesBase64,setImagesBase64] = useState([])
  const [imagesName,setImagesName] = useState([])


  const getImages = async () =>{
    const imagesList = await GetImagesPacienteConsultas(`https://${user.listClinic[0].serverRemote}:${user.listClinic[0].portRemote}`,Number(user.listClinic[0].pacienteId),token)
    let imageBase64Arr = []
    
    setImagesName(imagesList)
    for (let index = 0; index < imagesList.length; index++) {
      const element = imagesList[index];
      const imagesListB = await GetImagesblobPaciente(`https://${user.listClinic[0].serverRemote}:${user.listClinic[0].portRemote}`,Number(user.listClinic[0].pacienteId),token,element.name)
      imageBase64Arr.push(imagesListB)
    }
    
    
    setImagesBase64(imageBase64Arr)
  }

  const callback = downloadProgress => {
    const progress = downloadProgress.totalBytesWritten / downloadProgress.totalBytesExpectedToWrite;
    
  };
  
  
  
  const handleSaveImages = async (image,index) =>{
    var randomName = Math.random()*100000000000000000
    const imagename = imagesName[index]
    const downloadResumable = FileSystem.createDownloadResumable(
      `https://mactestes.ddns.net:10042/pacientes/${Number(user.listClinic[0].pacienteId)}/imagemnome?pacienteId=${Number(user.listClinic[0].pacienteId)}&ficheiro=${imagename.name}`,
      FileSystem.documentDirectory + randomName + '.jpg',
      {
        headers:{
          'NPAC-API-TOKEN': token
        }
      },
      callback
    );
    try {
      const { uri } = await downloadResumable.downloadAsync();
      
      await Sharing.shareAsync(uri,{
        dialogTitle:"Imagem Consulta"
      })
      // const result = await Share.share({
      //   message:
      //     'Imagem Consulta',
      //     url:uri
      // });
    } catch (e) {
    }

    // const mediaResult = await MediaLibrary.saveToLibraryAsync(filename);
  }

  useEffect(()=>{
    getImages();
  },[])
   return (
    <>
    <View style={{padding:20,backgroundColor:'#fff'}}>
      <Header name="Imagens das Consultas" />
      <ScrollView contentContainerStyle={{justifyContent:'space-between',flexDirection:'row',width:'100%',flexWrap:'wrap',height:'100%'}}  >
        {imagesBase64.map((element,index)=>{
          return (
            <View style={{height:150,width:'48%',borderRadius:10,backgroundColor:'rgba(224, 224, 224, 0.50)',justifyContent:'center',alignItems:'center'}}>
               <Image source={{uri:element}} resizeMode='cover' style={{height:150,width:180,borderRadius:10,position:'absolute'}}></Image>
              
                <TouchableOpacity style={{alignItems:'center',justifyContent:'center',backgroundColor:'rgba(9, 66, 48, 0.68)',height:60,width:60,borderRadius:20}} onPress={()=>handleSaveImages(element,index)}>
                <Feather name={'download'} size={18} color={'#32a852'} />
               </TouchableOpacity>
            
            </View>

          )
        })}
      </ScrollView>
    </View>
  
    </>
   
   )
}