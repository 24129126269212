import { Feather } from '@expo/vector-icons';
import { useAnimationState } from 'moti';
import React, { useEffect } from 'react';
import { View, Modal as RNModal } from 'react-native';
import { Theme } from '../../theme/theme';
import Button, { typeButton } from '../button';
import BigTitle from '../titles/bigTitle';
import Description from '../titles/description';
import SubTitle from '../titles/subTitle';
import { Container, Content } from './styles';

export enum ETypeModal {
    SUCESS = 'SUCESS',
    ERROR = 'ERROR',
    INFO = 'INFO'
}

interface IModal {
    show: boolean;
    closeModal: () => void;
    closeModalAccepeted: () => void;
}

const ModalConsentimento: React.FC<IModal> = ({ show, closeModal, closeModalAccepeted }: IModal) => {
    const animationState = useAnimationState({
        closed: {
            opacity: 0,
            scale: 0.5,
        },
        open: {
            opacity: 1,
            scale: 1,
        },
    });

    useEffect(() => {
        if (show) {
            animationState.transitionTo('open');
        } else {
            animationState.transitionTo('closed');
        }
    }, [show, animationState]);

    return (
        <RNModal transparent visible={show} animationType="none">
            <Container state={animationState}>
                <Content>
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', padding: 15 }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 10 }}>
                            <Feather name={'alert-triangle'} size={22} color={Theme.colors.yellow} />
                            <Description text={' Consentimento Dados Pessoais'} />
                        </View>
                        <SubTitle text={'Declaro, de forma esclarecida e informada, o meu consentimento para efeitos de tratamento de dados pessoais em conformidade com o definido em sede de RGPD.'} />
                    </View>
                    <View style={{ flexDirection: 'row', width: '100%', marginBottom: 10 }}>
                        <View style={{ flex: 0.5, marginLeft: 20, marginRight: 20 }}>
                            <Button action={closeModal} loading={false} text="Declinar" type={typeButton.ERROR}></Button>
                        </View>
                        <View style={{ flex: 0.5, marginLeft: 20, marginRight: 20 }}>
                            <Button action={closeModalAccepeted} loading={false} text="Aceitar" type={typeButton.PRIMARY}></Button>
                        </View>
                    </View>
                </Content>
            </Container>
        </RNModal>
    );
};

export default ModalConsentimento;