import * as React from 'react';
import { View } from 'react-native';
import LottieView from 'lottie-react-native';
import { ButtonApp, Container, TitleEmptyBooking } from './styles';
import Button, { typeButton } from '../button';
import WhiteLabel from '../titles/buttonLabel';
import { useNavigation } from '@react-navigation/native';
// import { Container } from './styles';


const EmptyHistory: React.FC = () => {
    const {navigate} = useNavigation()
    return <Container>
        <TitleEmptyBooking>
            NÃO TEM {"\n"}
             CONSULTAS
        </TitleEmptyBooking>
{/*         <LottieView */}
{/*             autoPlay */}
{/*             style={{ */}
{/*                 width: 100, */}
{/*                 height: 100, */}
{/*             }} */}
{/*             source={require('../../assets/animations/empty.json')} /> */}

    </Container>;
}

export default EmptyHistory;