import * as React from 'react';
import { View } from 'react-native';
import { Title } from '../titles/bigTitle/styles';
import SubTitle from '../titles/subTitle';
import { Container } from './styles';

// import { Container } from './styles';
interface IAppointment{
    doctorName:string;
    tipo:string
    date:string
    id:string
}
const Appointment: React.FC<IAppointment> = ({date,doctorName,tipo,id}:IAppointment) => {
  return <Container>
      <Title style={{color:'#fff',fontWeight:'bold',fontSize:15}}>{doctorName}</Title>
      <View style={{marginTop:5}}></View>
      <Title style={{color:'#fff',fontSize:10}}>{tipo}</Title>
      <View style={{marginTop:5}}></View>
      <Title style={{color:'#b9b9b9',fontSize:12}}>{date}</Title>
  </Container>;
}

export default Appointment;