import { StatusBar } from 'expo-status-bar';
import * as React from 'react';
import { useEffect } from 'react';
import AppLoading from 'expo-app-loading';
import { useFonts, Poppins_700Bold,Poppins_600SemiBold,Poppins_500Medium,Poppins_400Regular } from '@expo-google-fonts/poppins';
import { NavigationContainer } from '@react-navigation/native';
import AppRoutes from './src/routes/app.routes';
import { SafeAreaView, SafeAreaProvider } from 'react-native-safe-area-context';
import { ThemeProvider } from 'styled-components/native';
import { Theme } from './src/theme/theme';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
export default function App() {
    useEffect(() => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/service-worker.js')
          .then(registration => {
          })
          .catch(registrationError => {
          });
      }
    }, []);
  let [fontsLoaded] = useFonts({
    Poppins_700Bold,
    Poppins_600SemiBold,
    Poppins_500Medium,
    Poppins_400Regular
  });
  if(!fontsLoaded){
   return <AppLoading></AppLoading>
  }


  return (

  <SafeAreaProvider>
    <GestureHandlerRootView style={{flex:1,height:'100%',paddingTop:20}}>
    <SafeAreaView style={{flex:1,height:'100%'}} >
    <ThemeProvider theme={Theme}>
      <NavigationContainer>
          <AppRoutes></AppRoutes>
      </NavigationContainer>
      <StatusBar backgroundColor={Theme.colors.background}   />
    </ThemeProvider>

    </SafeAreaView>
    </GestureHandlerRootView>
  </SafeAreaProvider>
  );
}


