import create from 'zustand'
import { DataUser, User } from './model/User'


interface AppState {
    api: boolean;
    url: string;
    addApi: (api: boolean) => void;
    addUrl: (url: string) => void;
  }
  

export const AppAuth = create<AppState>(set => ({
    api: false,
    url:'',
    addApi: (api: boolean) => {
    set((state) => ({
        api,
    }));
  },
  addUrl: (url: string) => {
    set((state) => ({
        url
    }));
  },
}))