import { useNavigation } from '@react-navigation/native';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { View,Image, Text, ActivityIndicator, BackHandler, Platform } from 'react-native';
import Button, { typeButton } from '../../../components/button';
import Footer from '../../../components/footer';
import InputText from '../../../components/input';
import { InputType } from '../../../components/input/styles';
import Modal, { ETypeModal } from '../../../components/modal';
import ProgressBar from '../../../components/progressBar';
import Tabs, { TTabItem } from '../../../components/Tabs';
import BigTitle from '../../../components/titles/bigTitle';
import { DescriptionText } from '../../../components/titles/description/styles';
import { SaveUserInfo } from '../../../services/tactisService/auth';
import { GetUserInfo, GetConsentimento, PutConsentimento } from '../../../services/tactisService/auth';
import { GetImagePaciente, PostImagePaciente } from '../../../services/tactisService/act';
import { RegisterTactis } from '../../../services/tactisService/auth';
import { AppAuth } from '../../../store/app';
import { userAuth } from '../../../store/auth';
import { ContainerRegister } from '../../Auth/Register/styles';
import * as ImagePicker from 'expo-image-picker';
// import { Container } from './styles';
import AsyncStorage from '@react-native-async-storage/async-storage';


const Profile: React.FC = () => {
    const [show,setShow] = useState(false)
    const [showSucess,setShowSucess] = useState(false)

    const {navigate }= useNavigation()
    const {user,token} = userAuth()
    const {api,url} = AppAuth()
    const [userData,setUserData] = useState<UserDataObject[]>([])
    const [isLoading, setIsLoading] = useState(true);

    const [image,setImage] = useState(null)
//     const [email,setEmail] = useState('')
    const [name,setName] = useState('')
    const [nif,setNif] = useState('')
    const [birthDay,setBirthDay] = useState('')
    const [sex,setSex] = useState('')
    const [sns,setSns] = useState('')
    const [address,setAddress] = useState('')
    const [phone,setPhone] = useState('')
    const [city,setCity] = useState('')
    const [postCode1, setPostCode1] = useState('')
    const [postCode2,setPostCode2] = useState('')

    const [password,setPassword] = useState('')
    const [errorMessage,setErrorMessage] = useState('Não foi possivel cria a conta')

    const handleGetBirthDay = async (dateString)=> {
       try{
         // Split the date string by '-'
          const parts = dateString.split('-');
          // Extract year, month, and day from the parts array
          const year = parts[0];
          const month = parts[1];
          const day = parts[2];
          // Create a new date object with the extracted values
          const date = new Date(`${year}-${month}-${day}`);
          // Get the formatted date in dd/mm/yyyy format
          const formattedDate = `${day}/${month}/${year}`;
          return formattedDate;
       }
       catch{
           setErrorMessage('Não foi possivel retornar a data.')
       }
   }
    const handleGetUserData = async ()=> {
        try {
             const img = await GetImagePaciente(`https://${user.listClinic[0].serverRemote}:${user.listClinic[0].portRemote}`,user.listClinic[0].pacienteId,token)
             setImage(img)
             const result = await GetUserInfo(token,String(user.listClinic[0].pacienteId))
             if(result.status === 400){
                 setErrorMessage(result.error)
                 setShow(true)
             }
             let birth = await handleGetBirthDay(result.birth)
             setUserData(result)
             setName(result.nome);
             setBirthDay(birth);
             setNif(result.nif)
             setSex(result.sex)
             setPhone(result.telemovel)
             setAddress(result.morada)
             setCity(result.localidade)
             setPostCode1(result.codPost1)
             setPostCode2(result.codPost2)

            const resultConsentimento = await GetConsentimento(token,user.listClinic[0].pacienteId,`https://${user.listClinic[0].serverRemote}:${user.listClinic[0].portRemote}`)
             console.log(resultConsentimento);

             setIsLoading(false);

        } catch (error) {
            setErrorMessage('Não foi possivel ler os dados do utilizador')
            setShow(true)
        }
    }
    useEffect(()=>{
        handleGetUserData()
    },[])


    const handleSetBirthDay = async (dateString)=> {
        try{
          // Split the date string by '/'
          const parts = dateString.split('/');
          // Extract day, month, and year from the parts array
          const day = parts[0];
          const month = parts[1];
          const year = parts[2];
          // Create a new date object with the extracted values
          const date = new Date(`${year}-${month}-${day}`);
          // Get the formatted date in yyyy-mm-dd format
          const formattedDate = date.toISOString().slice(0, 10);
          setBirth(formattedDate)
        }
        catch{
            setErrorMessage('Não foi possivel alterar a data. Verifique o formato')
        }
    }

    const changeUserData = async () =>{
         try {
              // Api PORTCOV
              const result = await SaveUserInfo(token ,String(user.listClinic[0].pacienteId), phone, address, city, postCode1, postCode2 ,userData)
              if(result.status === 400){
                  setErrorMessage(result.error)
                  setShow(true)
                  return;
              }
              setShowSucess(true)

         } catch (error) {
             setErrorMessage('Não foi possivel editar a conta')
             setShow(true)
         }

    }


//     const handleGetImage = async ()=> {
//       if(!api){
//
//
//         return;
//       }
//     }

    const [imagePicker, setImagePicker] = useState(null);

    const pickImage = async () => {
      // No permissions request is necessary for launching the image library
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 0.2,
        base64:true
      });
      if (!result.cancelled) {
      setImage(result.uri);
          var data = new FormData();

          // Prepare the file object based on platform
          let file = null;
          if (Platform.OS === 'web') {
            // For web: Convert base64 to Blob and append to FormData
            const blob = await fetch(result.uri).then(r => r.blob());
            file = new File([blob], "userProfile.jpg", { type: 'image/jpg' });
          } else {
            // For mobile: Construct the file object as before
            const uri = Platform.OS === 'android' ? result.uri : result.uri.replace('file://', '');
            file = {
              uri: uri,
              name: 'userProfile.jpg',
              type: 'image/jpg'
            };
          }

          // Append the file to FormData
          data.append('myPhoto', file);

       await PostImagePaciente(`https://${user.listClinic[0].serverRemote}:${user.listClinic[0].portRemote}`,data,user.listClinic[0].pacienteId,token)
      }
    };

    const logOff = async () =>{
      try {
        await AsyncStorage.removeItem('@user')
        await AsyncStorage.removeItem('@token')
        navigate('Login')
       } catch (error) {
       }

     }
    useEffect(()=>{
      if(!api){
//         handleGetImage()
      }
    },[])
  return<>
   <ContainerRegister>
        {isLoading ? (
        <View style={{paddingTop:"70%",display:'flex',width:'100%',alignItems:'center', justifyContent:'center', }}>
        <ActivityIndicator size={70} color="#0000ff" />
        <BigTitle text={`Por favor aguarde`} />
        </View>
         ) : (
        <View style={{paddingLeft:20,paddingRight:20,paddingTop:20}}>
          <BigTitle  text={`Meus Dados`}/>
          {!api && image &&
          <View style={{flexDirection:'row',height:90,justifyContent:'center',alignItems:'center',width:'100%'}}>
           <Image style={{height:80,width:80,marginRight:10,marginTop:-5,marginTop:20}} resizeMode={'contain'} source={{uri:image}}  ></Image>
           <View  style={{width:150}}>
            <Button action={()=>{pickImage()}} loading={false} text="Alterar Foto" type={typeButton.PRIMARY}></Button>
           </View>

          </View>
        }
             <InputText value={name} getText={(text)=>setName(text)} editable={false} iconName='user' placeholder='Nome Completo' inputType='default' type={InputType.NORMAL} ></InputText>
             <InputText value={nif}  getText={(text)=>setNif(text)} editable={false} iconName='clipboard' placeholder='Nif' inputType='phone-pad' type={InputType.NORMAL}></InputText>
             <InputText value={phone}  getText={(text)=>setPhone(text)}  iconName='phone' placeholder='Telemóvel' inputType='phone-pad' type={InputType.NORMAL}></InputText>
             <InputText value={address} getText={(text)=>setAddress(text)} iconName='user' placeholder='Morada' inputType='default' type={InputType.NORMAL}></InputText>
             <InputText value={city} getText={(text)=>setCity(text)}  iconName='map-pin' placeholder='Localidade' inputType='default' type={InputType.NORMAL}></InputText>
             <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
             <InputText value={postCode1}  getText={(text)=>setPostCode1(text)} width='58%' iconName='hash' placeholder='Código Postal' inputType='number-pad' type={InputType.NORMAL}></InputText>
             <InputText value={postCode2}  getText={(text)=>setPostCode2(text)}  width='40%' placeholder='' inputType='number-pad' type={InputType.NORMAL}></InputText>
             </View>
        <View style={{marginTop:40}}>

        </View>
        {!api &&
           <Button action={()=>{changeUserData()}} marginBottom={10} loading={false} text="Alterar Dados" type={typeButton.PRIMARY}></Button>
        }
         {!api &&
           <Button action={()=>{navigate('AppointmentImages')}} loading={false} text="Imagens Consultas" type={typeButton.PRIMARY}></Button>
         }
         {!api &&
           <Button action={()=>{logOff()}} loading={false} text="Sair da Conta" type={typeButton.SECONDARY}></Button>
         }
        {/*<Button action={()=>{navigate('ContaCorrente')}} loading={false} text="Ver Conta Corrente" type={typeButton.SECONDARY}></Button> */}
        </View>
       )}
  </ContainerRegister>
       <Modal closeModal={()=>setShow(false)} show={show} description={errorMessage} title='Erro Conta' type={typeButton.ERROR} iconName='alert-circle' ></Modal>
       <Modal closeModal={()=>setShowSucess(false)} show={showSucess} description={"Informação Alterada com Sucesso"} title='Sucesso' type={typeButton.PRIMARY} iconName='check-square' ></Modal>

       <Footer activeIndex={2}></Footer>

  </>
}

export default Profile;