import SectionedMultiSelect from 'react-native-sectioned-multi-select';
import styled from 'styled-components/native';

export enum InputType {
    ERROR = 'ERROR',
    SUCESS = 'SUCESS',
    ACTIVE = 'ACTIVE',
    NORMAL = 'NORMAL'
}
interface IContainerSelect {
    type:InputType
}

export const ContainerSelect = styled.View<IContainerSelect>`
  background-color:${props=>props.theme.colors.background};
  border: 1px solid ${props=>props.type ==InputType.ACTIVE ? 
   props.theme.colors.primary : props.type ==InputType.ERROR ? 
   props.theme.colors.error : props.type ==InputType.SUCESS ? 
   props.theme.colors.sucess  :  props.theme.colors.seconday};
  flex-direction: row;
  padding-left: 15px;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  border-radius: 6px;
  height: 50px;
`;

export const SectionedMultiSelectItem = styled(SectionedMultiSelect)`
  width: 100%;
  height: 50px;
  background: #fff;
  border-color: transparent;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  color: #fff;
  font-family:${props=>props.theme.fonts.semibold};
`;
