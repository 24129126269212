import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { FlatList, Platform, ScrollView, View, Text } from 'react-native';
import { Calendar, LocaleConfig } from 'react-native-calendars';
import Button, { typeButton } from '../../../../components/button';
import  CalendarA from '../../../../components/Calendar';
import { HoursItem, HoursLabel } from '../../../../components/Calendar/styles';
import Header from '../../../../components/header';
import InputText from '../../../../components/input';
import Modal from '../../../../components/modal';
import SelectOptions, { itemSelect } from '../../../../components/selectOptions';
import { InputType } from '../../../../components/selectOptions/styles';
import Description from '../../../../components/titles/description';
import Title from '../../../../components/titles/title';
// import { CreateBookingApi, GetActsApi, GetVacaniesAPi } from '../../../../services/apiSrdoutor/act';
import { CreateBooking, GetActs, GetVacanies } from '../../../../services/tactisService/act';
import { AppAuth } from '../../../../store/app';
import { userAuth } from '../../../../store/auth';
import { ContainerBookingCreate } from './styles';
import Icon from 'react-native-vector-icons/MaterialIcons';

// import { Container } from './styles';

LocaleConfig.locales['pt'] = {
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  dayNames: ['Domingo' ,'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
  dayNamesShort: ['Dom.', 'Seg.', 'Ter.', 'Quar.', 'Qui.', 'Sex.', 'Sab.'],
  today: "Hoje",

};
LocaleConfig.defaultLocale = 'pt';
const BookingCreate: React.FC = () => {
    const {user,token} = userAuth()
    const {api,url} = AppAuth()
    const {navigate} = useNavigation()
    const [specialties,setSpecialties] = useState<itemSelect[]>([])
    const [selectspecialty,setSelectspecialty] = useState<itemSelect>()

    const [doctors,setDoctors] = useState<itemSelect[]>([])
    const [doctorBloqued,setDoctorBloqued] = useState(true)
    const [doctorId,setDoctorId] = useState(0)
    const [hourSelect,setHourSelect] = useState('')
    const todayDate = new Date();
    const [selectDate,setSelectDate] = useState(`${todayDate.getDate()}/${todayDate.getMonth() +1 }/${todayDate.getFullYear()}`)
    const [selectDateHour,setSelectDateHour] = useState('')
    const [selectDateCalendar,setSelectDateCalenda] = useState(`${todayDate.getFullYear()}-${todayDate.getMonth() +1 }-${todayDate.getDate().toString().length > 1 ? todayDate.getDate() : '0'+todayDate.getDate()}`)
    const [hoursVacanies,setHoursvacanies] = useState([]) 
    const [show,setShow] = useState(false)
    const [errorMessage,setErrorMessage] = useState('Não foi possível criar a marcação')

    const handleGetVacanies =async  (doctorIdA:string) => {

        const [day,month,year] = selectDate.split('/')
        const finalDate = `${day}/${month.length === 1 ? `0${month}` : month}/${year}`
        const vacanies = await GetVacanies(`https://${user.listClinic[0].serverRemote}:${user.listClinic[0].portRemote}`,user.listClinic[0].pacienteId,token,String(doctorIdA),finalDate)
        
     
        
        

        setHoursvacanies(vacanies.map(x=>x.data.substring(10, 16)))
        
    }

    const handleGetActs = async () => {

      const acts = await GetActs(`https://${user.listClinic[0].serverRemote}:${user.listClinic[0].portRemote}`,user.listClinic[0].pacienteId,token)
      const specialtiesArr:itemSelect[] = acts.reduce((acc, x) => {
          // Check if we have already added an entry with this id
          const exists = acc.find(item => item.id === Number(x.especialidade.id));
          if (!exists) {
            // If not, add this new entry to the accumulator
            acc.push({id: Number(x.especialidade.id), name: x.especialidade.nome});
          }
          return acc;
      }, [] as itemSelect[]);
      const doctorMap = new Map();

      for (const act of acts) {
        for (const doctor of act.medicos) {
          if (!doctorMap.has(doctor.id)) {
            // If the doctor ID is not already in the map, add it with its name and an array containing its first specialty
            doctorMap.set(doctor.id, { id: doctor.id, name: doctor.nome, specialties: [act.especialidade.nome] });
          } else {
            // If the doctor ID is already in the map, just add the new specialty to its list of specialties, if it's not already included
            const currentDoctor = doctorMap.get(doctor.id);
            if (!currentDoctor.specialties.includes(act.especialidade.nome)) {
              currentDoctor.specialties.push(act.especialidade.nome);
            }
          }
        }
      }

      // Convert the Map values to an array for the final output
      const doctorArr = Array.from(doctorMap.values()).map(doctor => ({
        id: doctor.id,
        name: doctor.name,
        // Optionally, you can concatenate the specialties into a string or handle them as you prefer
        specialties: doctor.specialties.join(', ')
      }));
      setDoctors(doctorArr)
      setSpecialties(specialtiesArr)
    }


    const handleSelectSpecialties = (value:any) => {
      setSelectspecialty(value[0])
      setDoctorBloqued(false)
    }
    const handleSelectDoctor = (value:any) => {
      setDoctorId(value[0])
      setDoctorBloqued(false)
      handleGetVacanies(value[0])
    }
    useEffect(()=>{
      handleGetActs()
    },[])
    useEffect(()=>{
      if(doctorId !== 0) {
        handleGetVacanies(String(doctorId))
      }
    },[selectDate])
  return <ContainerBookingCreate>
      <Header name="Nova Marcação" />
      <ScrollView>

      <View style={{flex:1}}>
 
      <View style={{marginTop:10}}></View>
      <Title text={'Especialidade e Médico'}/>
      <SelectOptions text={"Especialidade"} disable={false} selectOptions={handleSelectSpecialties} items={specialties} type={InputType.NORMAL}></SelectOptions>
      <SelectOptions text={"Médico"} disable={doctorBloqued} selectOptions={handleSelectDoctor} items={doctors} type={InputType.NORMAL}></SelectOptions>
      <View style={{marginTop:30}}></View>
      <Title text={`Data & Hora`}/>
      <View style={{marginTop:10}}></View>
    
        <Calendar
         renderArrow={(direction) => (
            <View>
                <Icon name={direction === 'left' ? 'arrow-back' : 'arrow-forward'} size={24} color="#3EA6A8" />
            </View>
          )}
            onDayPress={day => {

              setSelectDate(`${day.day}/${day.month}/${day.year}`)
              setSelectDateCalenda(day.dateString)
            }}
            markedDates={{
              [selectDateCalendar]: {
                selected: true,
                disableTouchEvent: true,
                color: '#769dc4',
                selectedColor: '#3EA6A8'
             }
             }}
            disableAllTouchEventsForDisabledDays={true}
            showsHorizontalScrollIndicator={true}
            hideArrows={false}
            enableSwipeMonths={true}
        />
      <View style={{marginTop:20}}></View>

        <FlatList
        data={hoursVacanies}
        horizontal
        showsHorizontalScrollIndicator={true}
        renderItem={(item)=>{
          return (
            <HoursItem  onPress={()=>{setHourSelect(item.item)}} style={{marginLeft:item.index === 0 ? 0 : 10}} active={hourSelect === item.item} >
            <HoursLabel  active={hourSelect === item.item} >{item.item}</HoursLabel>
            </HoursItem>
          )
        }}
        keyExtractor={(item, index) => item + index}
        ListEmptyComponent={<Description text={'Sem hórarios disponiveis para esta data'}></Description>}
      />


      </View>
      <View style={{marginTop:15}}></View>
        
      <Button action={async ()=>{

          const [day,month,year] = selectDate.split('/')
           const finalDate = `${day}/${month.length === 1 ? `0${month}` : month}/${year}${hourSelect}:00`
          
          const booking = await CreateBooking(`https://${user.listClinic[0].serverRemote}:${user.listClinic[0].portRemote}`,user.listClinic[0].pacienteId,token,String(doctorId),finalDate)
          if(booking){
            navigate('BookingSucess',{
              id:booking.id,
              medico:booking.medico,
              tipo:booking.especialidade,
              date:booking.dataInicio,
            })
          }else{
            setShow(true)
          }

         
         
      }} loading={false} text="Marcar Consulta" type={typeButton.PRIMARY}></Button>
    </ScrollView>
       <Modal closeModal={()=>setShow(false)} show={show} description={errorMessage} title='Erro Criação' type={typeButton.ERROR} iconName='alert-circle' ></Modal>
  </ContainerBookingCreate>;
}

export default BookingCreate;