import { Feather } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useAnimationState } from 'moti';
import React, { useEffect } from 'react';
import {  View } from 'react-native';
import Appointment from '../../../../components/appointment';
import BigTitle from '../../../../components/titles/bigTitle';
import Description from '../../../../components/titles/description';
import { Theme } from '../../../../theme/theme';

// import { QRCode } from 'react-native-custom-qr-codes-expo';
import Button, { typeButton } from '../../../../components/button';
 

const BookingSucess: React.FC = () => {
    const {navigate} = useNavigation()

    const {params} = useRoute()

  return   <View style={{flex:1,justifyContent:'center',alignItems:'center',padding:20}}>

  <Feather name={'check-square'} size={64} color={Theme.colors.primary } />
  <BigTitle text={'Marcação Agendada'}/>
  <View style={{marginTop:15}}></View>

  <Appointment id={params?.id} date={params?.date} tipo={params?.tipo}   doctorName={params?.medico} ></Appointment>
  <View style={{marginTop:25}}></View>


<View style={{marginTop:25}}></View>

<Button action={async ()=>{
            navigate('Home')
      }} loading={false} text="Sair" type={typeButton.PRIMARY}></Button>
</View>;
}

export default BookingSucess;