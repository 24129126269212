import * as React from 'react';
import { View } from 'react-native';
import { Title } from './styles';

// import { Container } from './styles';
interface ISubTitle {
    text:string
}
const SubTitle: React.FC<ISubTitle> = ({text}:ISubTitle) => {
  return <Title>{text}</Title>;
}

export default SubTitle;