import * as React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Onboarding from '../pages/Auth/Onboarding';
import Login from '../pages/Auth/Login';
import Register from '../pages/Auth/Register';
import Home from '../pages/Dashboard/Home';
import BookingCreate from '../pages/Dashboard/Booking/BookingCreate';
import RegisterPin from '../pages/Auth/RegisterPin';
import BookingSucess from '../pages/Dashboard/Booking/BookingSucess';
import BookingDetails from '../pages/Dashboard/Booking/BookingDetails';
import HistoryPage from '../pages/History';
import Profile from '../pages/Dashboard/Profile';
import ContaCorrente from '../pages/ContaCorrente';
import RecoverPassCode from '../pages/Auth/RecoverPassCode';
import RecoverPassEmail from '../pages/Auth/RecoverPassEmail';
import { RecoverPassEmailAPIC } from '../services/apiSrdoutor/auth';
import RecoverPassEmailPortCov from '../pages/Auth/RecoverPassEmailApiPortCov';
import { AppointmentImages } from '../pages/Dashboard/AppointmentImages';


const Stack = createNativeStackNavigator();

// import { Container } from './styles';

const AppRoutes: React.FC = () => {
 
  return   <Stack.Navigator screenOptions={{headerShown:false}}>
{/* <Stack.Screen name="Onboarding" component={Onboarding} /> */}

  <Stack.Screen name="Login" component={Login} />
  <Stack.Screen name="Home" component={Home} />

  <Stack.Screen name="Register" component={Register} />
  <Stack.Screen name="RegisterPin" component={RegisterPin} />
  
  <Stack.Screen name="RecoverPassCode" component={RecoverPassCode} />
  <Stack.Screen name="RecoverPassEmail" component={RecoverPassEmail} />
  <Stack.Screen name="RecoverPassEmailAPI" component={RecoverPassEmailPortCov} />

  
  <Stack.Screen name="BookingCreate" component={BookingCreate} />
  <Stack.Screen name="BookingSucess" component={BookingSucess} />

  <Stack.Screen name="BookingDetails" component={BookingDetails} />
  <Stack.Screen name="History" component={HistoryPage} />
  <Stack.Screen name="Profile" component={Profile} />
  <Stack.Screen name="ContaCorrente" component={ContaCorrente} />
  <Stack.Screen name="AppointmentImages" component={AppointmentImages} />


  
  
  </Stack.Navigator>;
}

export default AppRoutes;
