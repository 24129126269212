import React, { useEffect, useState } from 'react';
import { View ,Image} from 'react-native';
import { GetImagePaciente } from '../../services/tactisService/act';
import { AppAuth } from '../../store/app';
import { userAuth } from '../../store/auth';
import SubTitle from '../titles/subTitle';
import Title from '../titles/title';
import CurrentAccount from './currentAccount';
import { ContainerHeaderUser } from './styles';

// import { Container } from './styles';

const HeaderUser: React.FC = () => {
  const {user,token} = userAuth()
  const [image,setImage] = useState(null)
  const {api,url} = AppAuth()
  const handleGetImage = async ()=> {
    if(!api){
      const result = await GetImagePaciente(`https://${user.listClinic[0].serverRemote}:${user.listClinic[0].portRemote}`,user.listClinic[0].pacienteId,token)
      setImage(result)

      return;
    }
  }

  useEffect(()=>{
    if(!api){
      handleGetImage()
    }
  },[])

  return <ContainerHeaderUser>
      <View style={{flexDirection:'row'}}>
        {!api && image &&
        <Image style={{height:50,width:50,marginRight:10,marginTop:-5}} resizeMode={'contain'} source={{uri:image}}  ></Image>
        }
        <View>
          <Title text={'Bem-Vindo'}/>
          <SubTitle text={user?.user.name}/>
        </View>
       
      </View>
      <Image style={{height:50,width:50}} resizeMode={'contain'} source={require('../../assets/Logo.jpeg')}  ></Image>

      {/* <CurrentAccount></CurrentAccount> */}
  </ContainerHeaderUser>;
}

export default HeaderUser;