import axios from 'axios';
// const baseURL_prod = 'https://mactestes.ddns.net:10042/';
const baseURL_prod = 'https://tactiscloud01.ddns.net:39858/';

const api = axios.create({
  baseURL: baseURL_prod,
  httpsAgent: {
      rejectUnauthorized: false,
    }
});

export default api;
