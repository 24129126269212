import { useNavigation } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';
import { View,Image, BackHandler, Linking, Text  } from 'react-native';
import Button, { typeButton } from '../../../components/button';
import InputText from '../../../components/input';
import { InputType } from '../../../components/input/styles';
import Modal from '../../../components/modal';
import ProgressBar from '../../../components/progressBar';
import Tabs, { TTabItem } from '../../../components/Tabs';
import BigTitle from '../../../components/titles/bigTitle';
import Title from '../../../components/titles/title';
import { LoginApi } from '../../../services/apiSrdoutor/auth';
import { GetConsentimento, LoginTactis, PutConsentimento } from '../../../services/tactisService/auth';
import { AppAuth } from '../../../store/app';
import { userAuth } from '../../../store/auth';
import { ContainerLogin, Commands, ContainerIos } from './styles';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ModalConsentimento from '../../../components/modalConsentimento';
import { EvilIcons, Feather, Octicons } from '@expo/vector-icons';

// import { Container } from './styles';
const DEV = false;
const Login: React.FC = () => {
    const {navigate} = useNavigation()
    const {api,url} = AppAuth()
    const {addUser,addToken} = userAuth()

    const tabs:TTabItem[] = [
        {
            action:()=>{},
            active:true,
            name:'Entrar',
            visible:true,
        },
        {
            action:()=>{
                navigate('Register')
            },
            active:false,
            name:'Registar',
            visible:api
        }
    ] 

    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    const [show,setShow] = useState(false)
    const [showConsentimento,setShowConsentimento] = useState(false)
    const [loadingSubmit,setLoadingSubmit] = useState(false)
const [showIOSInstallMessage, setShowIOSInstallMessage] = useState(false);

    const [acceptConsentimento,setAcceptConsentimento] = useState(false)
    const [errorMessage,setErrorMessage] = useState('Não foi possivel cria a conta')
  const [showButton, setShowButton] = React.useState(false);
  const [deferredPrompt, setDeferredPrompt] = React.useState('');

    const isIOS = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    }
  useEffect(() => {

     window.addEventListener('beforeinstallprompt', (e) => {
          e.preventDefault();
          setDeferredPrompt(e);
          setShowButton(true);  // Show the install button
        });

  }, []);

    const handleSubmit = async () => {
        if(api){
            const resultLogin = await LoginApi(url,email,password)
            await AsyncStorage.setItem('@user',JSON.stringify(resultLogin.response))

            addUser(resultLogin.response)
            addToken(resultLogin.token)
            if(resultLogin.status === 400){
                setErrorMessage(resultLogin.error)
                setShow(true)
                return;
            }
             navigate('Home')
        }else{
            setLoadingSubmit(true)
            const resultLogin = await LoginTactis(email,password)

            if(resultLogin.error === 'Utilizador sem endereço de email validado!' && resultLogin.status === 400){
                navigate('RegisterPin',{
                    email,
                    password:password,
                })
                setLoadingSubmit(false)
                return;            }
            else if(resultLogin.status === 400){
                setErrorMessage(resultLogin.error)
                setShow(true)
                setLoadingSubmit(false)
                return;
            }
            else{


            const resultConsentimento = await GetConsentimento(resultLogin.token,resultLogin.response.listClinic[0].pacienteId,`https://${resultLogin.response.listClinic[0].serverRemote}:${resultLogin.response.listClinic[0].portRemote}`)

            if(resultConsentimento){
                await AsyncStorage.setItem('@user',JSON.stringify(resultLogin.response))
                await AsyncStorage.setItem('@token',JSON.stringify(resultLogin.token))
                addUser(resultLogin.response)
                addToken(resultLogin.token)
                navigate('Home')
            }else{
                setLoadingSubmit(false)
                setShowConsentimento(true)
            }
            if(acceptConsentimento){
               await PutConsentimento(resultLogin.token,resultLogin.response.listClinic[0].pacienteId,`https://${resultLogin.response.listClinic[0].serverRemote}:${resultLogin.response.listClinic[0].portRemote}`,true)
            }

            return;
            }
        }
    }
         React.useEffect(() => {
            setShowButton(!isPWA());  // Set the visibility of the button based on whether it's a PWA
          }, []);
    const isPWA = () => {
      return (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
    }
    const handleDownload = () => {
        if(isIOS()){
          setShowButton(false);  // Hide the install button after prompt
          setShowIOSInstallMessage(true);
        }else{
          if (deferredPrompt) {
            // Show the prompt
            deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            deferredPrompt.userChoice.then((choiceResult) => {
              if (choiceResult.outcome === 'accepted') {
                 setDeferredPrompt(null);
                 setShowButton(false);  // Hide the install button after prompt
              } else {
                console.log('User dismissed the A2HS prompt');
              }


            });
          }
       }
    };
    const handleAcceptConsentimento = async () => {
        setAcceptConsentimento(true)
        setShowConsentimento(!showConsentimento)
    }

    useEffect(() => {
              const backAction = () => {
                // Perform any custom logic you want before preventing the default back button behavior

                // Prevent default back button behavior
                return true;
              };

              const backHandler = BackHandler.addEventListener(
                'hardwareBackPress',
                backAction
              );
              // Cleanup the event listener on component unmount
              return () => backHandler.remove();
            },
      []);
    const RecoverPassEmail = () =>{
        if(api){
            navigate('RecoverPassEmailAPI')
            return;

        }
        navigate('RecoverPassEmail')
    }

  return <ContainerLogin >
      <View style={{height:185}}>
        <Tabs tabs={tabs}></Tabs>
        <View style={{marginTop:40}}/>
        <View style={{flexDirection:'row',justifyContent:'flex-start',alignItems:'center',height:110}}>
            <Image style={{height:120,width:130}} resizeMode={'contain'} source={require('../../../assets/Logo.jpeg')}  ></Image>
            <View>
                <Title text={'Bem-vindo ao'}/>
                <BigTitle  text={'MEDICAL ART \n CENTER'}/>
            </View>
        </View>
      </View>
      <View style={{marginTop:40}}/>

      <InputText getText={(text)=>setEmail(text)}  iconName='mail' placeholder='Email' inputType='email-address'  type={InputType.NORMAL}></InputText>
      <InputText getText={(text)=>setPassword(text)}  password={true} iconName='lock' placeholder='Palavra-Passe' inputType='default'  type={InputType.NORMAL}></InputText>
      <View style={{marginTop:60}}/>

      <Button action={()=>handleSubmit()} loading={loadingSubmit} text="Entrar na conta" type={typeButton.PRIMARY}></Button>
      <Button action={()=>{RecoverPassEmail()}} loading={false} text="Recuperar Senha" type={typeButton.SECONDARY}></Button>
       {showButton && (
              <Button action={()=>{handleDownload()}} text="Instalar Aplicação" />
            )}
        {showIOSInstallMessage && (
               <ContainerIos >
                    <Commands ><Text style={{fontWeight:"bold"}}>1.</Text> Clique em: <EvilIcons style={{textAlign:'bottom'}} name="share-apple" size={24} color="black" /> </Commands>
                    <Commands ><Text style={{fontWeight:"bold"}}>2.</Text> Selecione a opção adicionar ao ecrã principal <Feather name="plus-square" size={16} color="black" /> </Commands>
                    <Commands ><Text style={{fontWeight:"bold"}}>3.</Text> Clique em adicionar</Commands>
                  </ContainerIos>
            )}
      <Button action={() => {
                  const url = "https://medicalartcenter.pt/wp-content/uploads/2023/01/Politica-Privacidade_MAC_V2.pdf"; // Replace with your actual URL
                  Linking.openURL(url).catch(err => console.error("Failed to open URL:", err));
                }} loading={false} text="Politica de privacidade" type={typeButton.SECONDARY}></Button>

       <Modal closeModal={()=>setShow(false)} show={show} description={errorMessage} title='Erro Conta' type={typeButton.ERROR} iconName='alert-circle' ></Modal>
       <ModalConsentimento closeModalAccepeted={handleAcceptConsentimento} closeModal={()=>setShowConsentimento(false)} show={showConsentimento} ></ModalConsentimento>
       <Image style={{height:140,width:310}} resizeMode={'contain'} source={require('../../../assets/financiamento.png')}  ></Image>

  </ContainerLogin>;
}

export default Login;