import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { TitleEmptyBooking } from '../../components/emptyBooking/styles';
import Header from '../../components/header';
import BigTitle from '../../components/titles/bigTitle';
import { GetContaCorrenteApi } from '../../services/apiSrdoutor/act';
import { GetContaCorrente,GetSaldo, RootObject } from '../../services/tactisService/act';
import { AppAuth } from '../../store/app';
import { userAuth } from '../../store/auth';
import { ContainerContaCorrente, ContainerMoney, MoneyDateText, MoneyText, MethodText, DocText, SaldoText } from './style';

// import { Container } from './styles';

const ContaCorrente: React.FC = () => {
    const {user,token} = userAuth()
    const {api,url} = AppAuth()
    const [contacorrente,setContaCorrente] = useState<RootObject[]>([])
    const [saldo,setSaldo] = useState<RootObject[]>([])

    const handleGetContaCorrente =async () => {
        if(api){
            
            const vacanies = await GetContaCorrenteApi(url,String(user.listClinic[0].pacienteId),token)
            setContaCorrente(vacanies)
            return;
        }
        const vacanies = await GetContaCorrente(`https://${user.listClinic[0].serverRemote}:${user.listClinic[0].portRemote}`,user.listClinic[0].pacienteId,token)
        setContaCorrente(vacanies)
        const balance = await GetSaldo(`https://${user.listClinic[0].serverRemote}:${user.listClinic[0].portRemote}`,user.listClinic[0].pacienteId,token)
        setSaldo(balance)
    }
    function getMethod(text){
        let method = '';
        switch (text) {
            case 'NUM':
              method = 'Numerário';
              break;
            case 'MB':
              method = 'Multibanco';
              break;
            case 'TRF':
              method = 'Transferência Bancária';
              break;
            case 'CHQ':
              method = 'Cheque';
              break;
            case 'FN':
              method = 'Financiamento';
              break;
            case 'CR':
              method = 'Cartão de Crédito';
              break;
            default:
              method = 'N/D';
        }
      return method;
    }
    useEffect(()=>{
        handleGetContaCorrente()
      },[])
  return <ContainerContaCorrente>
      <Header saldo={saldo} name="Conta Corrente" />
      <View style={{marginTop:10}}></View>
       <SaldoText>Saldo: {saldo.saldo}€</SaldoText>

   {contacorrente== null ?
    <TitleEmptyBooking>
    NÃO TEM {"\n"}
    CONTA CORRENTE
</TitleEmptyBooking>

   :contacorrente.length === 0 ? 
        <TitleEmptyBooking>
        NÃO TEM {"\n"}
        CONTA CORRENTE
    </TitleEmptyBooking>
   
   :
   contacorrente.map(x=>{
       return (
        <ContainerMoney style={{elevation:2,backgroundColor:'#fff'}}>
        <DocText>{x.docref} </DocText>
        <MoneyText isPayment = {x.classe === 'db1.C_Pagamento' ? true: false}>{ x.classe === 'db1.C_Pagamento' ? '+' : '-'}{x.total}€</MoneyText>
        {x.classe === 'db1.C_Pagamento' ? <MethodText>Método: {getMethod(x.mp)}</MethodText> : ''}
        <MoneyDateText>{x.datadoc}</MoneyDateText>
    </ContainerMoney>
       )
   })}
     
      
  </ContainerContaCorrente>;
}

export default ContaCorrente;