import React, { useRef } from 'react';
import { useState } from 'react';
import { TextInput, View } from 'react-native';
import Button, { typeButton } from '../../../components/button';
import InputText from '../../../components/input';
import { InputType } from '../../../components/input/styles';
import Modal, { ETypeModal } from '../../../components/modal';
import ProgressBar from '../../../components/progressBar';
import Tabs, { TTabItem } from '../../../components/Tabs';
import BigTitle from '../../../components/titles/bigTitle';
import Description from '../../../components/titles/description';
import { DescriptionText } from '../../../components/titles/description/styles';
import {  LoginTactis, RegisterCodeTactis, RegisterTactis ,RecoveryPasswordCodeEmail,RecoveryPasswordUserEmail} from '../../../services/tactisService/auth';
import { ContainerRegister, InputCode } from './styles';
import LottieView from 'lottie-react-native';
import { Theme } from '../../../theme/theme';
import { useNavigation, useRoute } from '@react-navigation/native';
import Header from '../../../components/header';

// import { Container } from './styles';

const RecoverPassCode: React.FC = () => {
    const [show,setShow] = useState(false)
    const {navigate} = useNavigation()
    const {params}= useRoute()

    const [password,setPassword] = useState('')
    const [code,setCode] = useState('')


    const [errorMessage,setErrorMessage] = useState('Não foi possivel cria a conta')
    const handleSubmit = async () => {
        try {
            const result = await RecoveryPasswordCodeEmail(params?.email,code,password)
            if(result.status === 400){
                setErrorMessage(result.error)
                setShow(true)
                return; 
            }
            navigate('Home')
        } catch (error) {
            
            setErrorMessage('Não foi possivel cria a conta')
           setShow(true) 
        }
        
    }
  
  return<>
   <ContainerRegister>
        <View style={{paddingLeft:20,paddingRight:20,paddingTop:20}}>
          <Header name="Recuperar Senha" />
          <View style={{marginTop:10}}>
        
          <Description text={'Foi enviado para o email de um código de validação. Insira aqui para recuperar a senha '} />
        </View>
        </View>

        <View style={{paddingLeft:20,paddingRight:20}}>
             <InputText getText={(text)=>setCode(text)}  iconName='code' placeholder='Codigo' inputType='number-pad' type={InputType.NORMAL}></InputText>
             <InputText password getText={(text)=>setPassword(text)}  iconName='mail' placeholder='Nova Senha' inputType='default' type={InputType.NORMAL}></InputText>
        </View>
        <View style={{width:'100%',justifyContent:'center',alignItems:'center',marginTop:20,padding:20}}>
          <Button action={()=>handleSubmit()} loading={false} text="Gravar" type={typeButton.PRIMARY}></Button>
       </View>
  </ContainerRegister>
       <Modal closeModal={()=>setShow(false)} show={show} description={errorMessage} title='Erro Conta' type={typeButton.ERROR} iconName='alert-circle' ></Modal>
  </>
}

export default RecoverPassCode;