import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';
import { View, BackHandler } from 'react-native';
import Button, { typeButton } from '../../../components/button';
import Footer from '../../../components/footer';
import Header from '../../../components/header';
import InputText from '../../../components/input';
import { InputType } from '../../../components/input/styles';
import Modal, { ETypeModal } from '../../../components/modal';
import ProgressBar from '../../../components/progressBar';
import Tabs, { TTabItem } from '../../../components/Tabs';
import BigTitle from '../../../components/titles/bigTitle';
import { DescriptionText } from '../../../components/titles/description/styles';
import { RegisterApi } from '../../../services/apiSrdoutor/auth';
import { RegisterTactis,RecoveryPasswordUserEmail } from '../../../services/tactisService/auth';
import { AppAuth } from '../../../store/app';
import { ContainerRegister } from './styles';

// import { Container } from './styles';

const RecoverPassEmail: React.FC = () => {
    const [show,setShow] = useState(false)
    const {api,url} = AppAuth()

    const [email,setEmail] = useState('')

    const {navigate }= useNavigation()
   
    const [errorMessage,setErrorMessage] = useState('Não foi possivel cria a conta')

    const handleSubmit = async () => {
        try {
            // Api TACTIS
            const result = await RecoveryPasswordUserEmail(email)
            if(result.status === 400){
                setErrorMessage(result.error)
                setShow(true)
                return; 
            }
            navigate('RecoverPassCode',{
                email,
            })
        } catch (error) {
            setErrorMessage('Não foi possivel cria a conta')
           setShow(true) 
        }
        
    }
  return<>
   <ContainerRegister>
        <View style={{padding:20}}>
        <Header name="Recuperar Senha" />
       </View>

        <View style={{paddingLeft:20,paddingRight:20}}>
             <InputText getText={(text)=>setEmail(text)}  iconName='mail' placeholder='Email' inputType='email-address' type={InputType.NORMAL}></InputText>
        </View>
        <View style={{width:'100%',justifyContent:'center',alignItems:'center',marginTop:20,padding:20}}>
          <Button action={()=>{
              handleSubmit()
          }} loading={false} text="Recuperar" type={typeButton.PRIMARY}></Button>
       </View>

  </ContainerRegister>
       <Modal closeModal={()=>setShow(false)} show={show} description={errorMessage} title='Erro Conta' type={typeButton.ERROR} iconName='alert-circle' ></Modal>
  </>
}

export default RecoverPassEmail;