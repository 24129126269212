import { MotiView } from 'moti';
import styled from 'styled-components/native';

export const Container = styled.View`
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;

interface ITabItem {
    active:boolean
}

export const TabItem = styled(MotiView)<ITabItem>`
    background:${props=>props.active ? props.theme.colors.primary: props.theme.colors.background};
    margin-right: 10px;
    border: 1px solid ${props=>props.active ? props.theme.colors.background: props.theme.colors.primary};
    border-radius: 6px;
    width: 84px;
    justify-content: center;
    align-items: center;
    height: 35px;
`