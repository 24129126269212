import * as React from 'react';
import { View } from 'react-native';
import { typeButton } from '../../button';
import {  Title} from './styles';

// import { Container } from './styles';
interface IWhiteLabel {
    text:string
    typeButton:typeButton
}
const WhiteLabel: React.FC<IWhiteLabel> = ({text,typeButton}:IWhiteLabel) => {
  return <Title typeButton={typeButton}>{text}</Title>;
}

export default WhiteLabel;