interface ThemeType {
  colors: {
    background: string;
    primary: string;
    title: string;
    subTitle: string;
    yellow: string;
    seconday: string;
    error: string;
    sucess: string;
    disable: string;
  };
  fonts: {
    bold: string;
    semibold: string;
    medium: string;
    regular: string;
  };
  fontSize: {
    title: number;
    subTitle: number;
    label: number;
    description: number;
    text: number;
  };
}

export const Theme = {
  colors: {
    background:'#ffff',
    primary:'#48c2c5',
    title:'#000',
    subTitle:'#8D8D8D',
    yellow:'#96830A',
    seconday:'#3EA6A8',
    error:'#E8505B',
    sucess:'#2FCB82',
    disable:'#EBF1F9'
  },
  fonts:{
    bold:'Poppins_700Bold',
    semibold:'Poppins_600SemiBold',
    medium:'Poppins_500Medium',
    regular:'Poppins_400Regular',
  },
  fontSize:{
    title:27,
    subTitle:16,
    label:14,
    description:12,
    text:10,
  },
};

