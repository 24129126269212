import styled from 'styled-components/native';

export const ContainerHeader = styled.View`
  width: 100%;
  height: 56px;
  flex-direction: row;
  align-items: center;
`;

export const TitleHeader = styled.Text`
  font-family: ${props=>props.theme.fonts.bold};
  font-size: 22;
  color: ${props=>props.theme.colors.title};
`;
