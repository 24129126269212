import styled from 'styled-components/native';

export enum InputType {
    ERROR = 'ERROR',
    SUCESS = 'SUCESS',
    ACTIVE = 'ACTIVE',
    NORMAL = 'NORMAL'
}
interface IContainerInput {
    type:InputType
}

export const ContainerInput = styled.View<IContainerInput>`
  background-color:${props=>props.theme.colors.background};
  border: 1px solid ${props=>props.type ==InputType.ACTIVE ? 
   props.theme.colors.primary : props.type ==InputType.ERROR ? 
   props.theme.colors.error : props.type ==InputType.SUCESS ? 
   props.theme.colors.sucess  :  props.theme.colors.seconday};
  flex-direction: row;
  padding-left: 15px;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  border-radius: 6px;
  height: 50px;
  alignItems: center;
  justifyContent: space-between;
`;
