import styled from 'styled-components/native';

export const Container = styled.View`
  justify-content: center;
  flex: 1;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
`;

export const TitleEmptyBooking = styled.Text`
 font-family: ${props=>props.theme.fonts.bold};
 font-size: ${props=>props.theme.fontSize.subTitle};
 color: ${props=>props.theme.colors.seconday};
 text-align: center;
` 
export const ButtonApp = styled.TouchableOpacity`
  width: 215px;
  height: 50px;
  background: ${props=>props.theme.colors.primary};
  border-radius: 6px;
  justify-content: center;
  align-items: center;
`;
