import * as React from 'react';
import { View } from 'react-native';
import { Title } from './styles';
import styled from 'styled-components/native';

// import { Container } from './styles';
interface IBigTitle {
    text:string
}
const BigTitle: React.FC<IBigTitle> = ({text}:IBigTitle) => {
  return <Title>{text}</Title>;
}

export default BigTitle;