import axios from "axios";
import { ResponseApi } from "../../config/responseApi";
import { userAuth } from "../../store/auth";
import { Acts } from "../../store/model/Acts";
import { Vacancies } from "../../store/model/Vacancies";

export const GetActs = async (url:string,userId:string,token:string):Promise<Acts[]> => {
    try {
      

        const result = await axios.get(`${url}/atos?pacienteId=${userId}`,{
          headers:{
            'NPAC-API-TOKEN': token
          }
        })
        
        const acts:Acts[] = result.data
        return acts
    } catch (error) {
      
      
      
      

        const response:ResponseApi ={
            response:null,
            status:400,
        } 
    }
}
export const GetVacanies = async (url:string,userId:string,token:string,doctorId:string,date:string):Promise<Vacancies[]> => {
  try {
    
    
      const result = await axios.get(`${url}/consultas/vagas?pacienteId=${userId}&medicoId=${doctorId}&data=${date}`,{
        headers:{
          'NPAC-API-TOKEN': token
        }
      })
      const vacancies:Vacancies[] = result.data
      return vacancies
  } catch (error) {
    
   
      const response:ResponseApi ={
          response:null,
          status:400,
      } 
  }
}


export interface BookingI {
  id: number;
  dataInicio: string;
  duracao: number;
  medico: string;
  especialidade: string;
  anotacao: string;
  entidade: string;
  servico: string;
}

export const CreateBooking = async (url:string,userId:string,token:string,doctorId:string,date:string):Promise<BookingI> => {
  try {
    var options = {
      method: 'POST',
      url: `${url}/consultas`,
      params: {
        servicoId: '1',
        pacienteId: userId,
        medicoId: doctorId,
        atoId: '15',
        convenioId: '45',
        data: date,
        duracao: '30'
      },
      headers: {
        'npac-api-token': token
      }
    };
    
     
    const result = await axios.request(options)
    
      return result.data[0]
  } catch (error) {
    

   return null
  }
}


export interface Status {
  id: number;
  num: number;
  nome: string;
  cor: string;
  titulo: string;
}

export interface BookingObject {
  id: number;
  dataInicio: string;
  status: Status;
  duracao: number;
  medicoId: string;
  medico: string;
  especialidade: string;
  anotacao: string;
  entidade: string;
  servico: string;
}

export const GetBooking = async (url:string,userId:string,token:string):Promise<BookingObject[]> => {
  try {

      const result = await axios.get(`${url}/consultas/todas?pacienteId=${userId}`,{
        headers:{
          'NPAC-API-TOKEN': token
        }
      })
      const bookingObject:BookingObject[] = result.data
      return bookingObject
  } catch (error) {
      const response:ResponseApi ={
          response:null,
          status:400,
      }
      return 401

  }
}

export interface RootObject {
  id: number;
  classe: string;
  benef: string;
  datadoc: string;
  docref: string;
  mp: string;
  total: number;
}

export const GetContaCorrente = async (url:string,userId:string,token:string):Promise<RootObject[]> => {
  try {
      const result = await axios.get(`${url}/contacorrente/historico?pacienteId=${userId}`,{
        headers:{
          'NPAC-API-TOKEN': token
        }
      })
      const bookingObject:RootObject[] = result.data
      return bookingObject
  } catch (error) {
    
      const response:ResponseApi ={
          response:null,
          status:400,
      } 
  }
}
export const GetSaldo = async (url:string,userId:string,token:string):Promise<RootObject[]> => {
  try {
      const result = await axios.get(`${url}/contacorrente/saldo?pacienteId=${userId}`,{
        headers:{
          'NPAC-API-TOKEN': token
        }
      })
      const bookingObject:RootObject[] = result.data
      return bookingObject
  } catch (error) {

      const response:ResponseApi ={
          response:null,
          status:400,
      }
  }
}


export const CheckInAppointment = async (url:string,consultaId:string,pacienteId:number,token:string):Promise<BookingI> => {
  try {
    var options = {
      method: 'POST',
      url: `${url}/consultas/checkin?consultaId=${consultaId}&pacienteId=${pacienteId}`,
      params: {},
      headers: {
        'npac-api-token': token
      }
    };
    
    const result = await axios.request(options)
    
      return result.data
  } catch (error) {
    

   return null
  }
}




const blobToBase642 = async (url) => {
  return new Promise(async (resolve, _) => {
    // do a request to the blob uri
    const response = await fetch(url);

    // response has a method called .blob() to get the blob file
    const blob = await response.blob();

    // instantiate a file reader
    const fileReader = new FileReader();

    // read the file
    fileReader.readAsDataURL(blob);

    fileReader.onloadend = function(){
      resolve(fileReader.result); // Here is the base64 string
    }
  });
};


export const GetImagesblobPaciente = async (url:string,userId:string,token:string,ficheiro:string):Promise<any> => {
  try {
    const result = await axios.get(`${url}/pacientes/${userId}/imagemnome?pacienteId=${userId}&ficheiro=${ficheiro}`,{
      headers:{
        'NPAC-API-TOKEN': token
      },
      responseType:'blob'
    })
     const result2 =await blobToBase64(result.data);
    return result2
  } catch (error) {
    
      const response:ResponseApi ={
          response:null,
          status:400,
      } 
  }
}


export const GetImagesPacienteConsultas = async (url:string,userId:string,token:string):Promise<any> => {
  try {
      
      const result = await axios.get(`${url}/pacientes/${userId}/imagens?pacienteId=${userId}`,{
        headers:{
          'NPAC-API-TOKEN': token
        },
      })
      return result.data
  } catch (error) {
    
      const response:ResponseApi ={
          response:null,
          status:400,
      } 
  }
}


export const GetImagePaciente = async (url:string,userId:string,token:string):Promise<string> => {
  try {
      const result = await axios.get(`${url}/pacientes/imagem?pacienteId=${userId}`,{
        headers:{
          'NPAC-API-TOKEN': token
        },
        responseType:'blob'
      })
       const result2 = await blobToBase64(result.data);
      return result2
  } catch (error) {
    
      const response:ResponseApi ={
          response:null,
          status:400,
      } 
  }
}


const blobToBase64 = blob => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});


export const PostImagePaciente = async (url:string,data:any,pacienteId:number,token:string):Promise<BookingI> => {
  try {
    var options = {
      method: 'POST',
      url: `${url}/pacientes/foto?pacienteId=${pacienteId}`,
      params: {},
      headers: {
        'Content-Type': "multipart/form-data;",
        'NPAC-API-TOKEN': token
      },
      data : data
    };
    const result = await axios.request(options)
     return result.data
  } catch (error) {
    

   return null
  }
}

