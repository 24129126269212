import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import Appointment from '../../components/appointment';
import EmptyHistory from '../../components/emptyBooking';
import EmptyBooking from '../../components/emptyBooking';
import Footer from '../../components/footer';
import HeaderUser from '../../components/headerUser';
import InputText from '../../components/input';
import BigTitle from '../../components/titles/bigTitle';
// import { GetBookingHistoryApi } from '../../services/apiSrdoutor/act';
import { GetBooking } from '../../services/tactisService/act';
import { AppAuth } from '../../store/app';
import { userAuth } from '../../store/auth';
import { ContainerHome } from './styles';

// import { Container } from './styles';

const HistoryPage: React.FC = () => {
  const {user,token} = userAuth()
  const {navigate} = useNavigation()
  const {api,url} = AppAuth()

  const [bookings,setBokings] = useState<BookingObject[]>([])
  const handleGetBooking = async ()=> {
//     if(api){
//       const result = await GetBookingHistoryApi(url,user.listClinic[0].pacienteId,token)
//       setBokings(result)
//       return
//     }
    const result = await GetBooking(`https://${user.listClinic[0].serverRemote}:${user.listClinic[0].portRemote}`,user.listClinic[0].pacienteId,token)
    setBokings(result.filter(x=>x.status.id === 11))
  }
  useEffect(()=>{
    handleGetBooking()
  },[])
  return <React.Fragment>
   <ContainerHome>

    <View style={{marginBottom:20}}></View>
          <BigTitle  text={'Histórico'}/>
          <View style={{marginBottom:20}}></View>

    {bookings.length > 0  ? 
    <ScrollView showsVerticalScrollIndicator>

       { bookings.map(booking=>{
          return (
            <>
            <TouchableOpacity onPress={()=>  navigate('BookingDetails',{
              id:booking.id,
              medico:booking.medico,
              tipo:booking.especialidade,
              date:booking.dataInicio,
            })}>

            <Appointment id={booking?.id} date={booking?.dataInicio} tipo={booking.especialidade}   doctorName={booking.medico} ></Appointment>
            </TouchableOpacity>

            <View style={{marginBottom:20}}></View>
            </>
          )
        })}
    </ScrollView>

    :<EmptyHistory></EmptyHistory> }
  </ContainerHome>
  <Footer activeIndex={1}></Footer>
  </React.Fragment>
}

export default HistoryPage;