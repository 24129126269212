import * as React from 'react';
import { View } from 'react-native';
import { Text } from './styles';

// import { Container } from './styles';
interface ITitle {
    text:string
}
const Title: React.FC<ITitle> = ({text}:ITitle) => {
  return <Text>{text}</Text>;
}

export default Title;