import styled from 'styled-components/native';

export const ContainerContaCorrente = styled.ScrollView`
  background-color: ${props => props.theme.colors.background};
  padding: 20px;
  height: 100%;
  padding-top: 10px;
  width: 100%;
`

export const ContainerMoney = styled.View`
    height: 100px;
    border-radius: 10px;
    padding: 30px;
    width: 100%;
    justify-content:center;
    flex-direction: column;
    margin-bottom: 15px;
`
export const MoneyText = styled.Text`
    font-family: ${props=>props.theme.fonts.bold};
 font-size: ${props=>props.theme.fontSize.subTitle};
 color: ${ props=>props.isPayment === true ? props=>props.theme.colors.sucess : props=>props.theme.colors.error};
`
export const SaldoText = styled.Text`
    font-family: ${props=>props.theme.fonts.bold};
 font-size: ${props=>props.theme.fontSize.subTitle};
 margin: 5px;
 color: black;
`
export const MethodText = styled.Text`
    font-family: ${props=>props.theme.fonts.bold};
 font-size: ${props=>props.theme.fontSize.description};
 color: ${ props=>props.theme.colors.subTitle};
`
export const DocText = styled.Text`
    font-family: ${props=>props.theme.fonts.bold};
 font-size: ${props=>props.theme.fontSize.text};
 color: ${ props=>props.theme.colors.subTitle};
 text-align: right;
`
export const MoneyDateText = styled.Text`
    font-family: ${props=>props.theme.fonts.bold};
 font-size: ${props=>props.theme.fontSize.subTitle};
 color: ${props=>props.theme.colors.primary};
`