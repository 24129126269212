import * as React from 'react';
import { View } from 'react-native';
import { DescriptionText } from './styles';

// import { Container } from './styles';
interface IDescription {
    text:string
}
const Description: React.FC<IDescription> = ({text}:IDescription) => {
  return <DescriptionText>{text}</DescriptionText>;
}

export default Description;