import { Feather } from '@expo/vector-icons';
import React, { useState } from 'react';
import { KeyboardTypeOptions,  View } from 'react-native';
import { ContainerSelect, InputType, SectionedMultiSelectItem } from './styles';
import {MaterialIcons} from '@expo/vector-icons';
import { Theme } from '../../theme/theme';


export type itemSelect = {
  name:string;
  id:number;
}


interface ISelectOptions{
    type:InputType
    disable:boolean
    text:string
    items:itemSelect[]
    selectOptions: (items:itemSelect) => void
  }
const SelectOptions:React.FC<ISelectOptions> = ({type,items,selectOptions,disable,text}:ISelectOptions) => {
    const [inputTypeState,setInputTypeState] = useState(type)
    const [selectOption,setSelectOption] = useState([])

  return <ContainerSelect type={inputTypeState}>
          <SectionedMultiSelectItem
                      items={items}
                      searchPlaceholderText={`Procurar por ${text}`}
                      IconRenderer={MaterialIcons}
                      uniqueKey="id"
                      subKey="children"
                      selectText=""
                      showDropDowns={false}
                      single
                      disabled={disable}
                      styles={{
                        selectToggle: {
                          width: '135%',
                          justifyContent: 'center',
                          alignItems: 'center',
                        },

                      }}
                      
                      selectText={text}
                      colors={{
                        primary: Theme.colors.primary,
                      }}
                      onSelectedItemsChange={(items)=>{selectOptions(items);setSelectOption(items)}}
                      confirmText="Confirmar"
                      selectedItems={selectOption}
                    />

  </ContainerSelect>;
}

export default SelectOptions;