import React, { useState } from 'react';
import { KeyboardTypeOptions, View, TextInput } from 'react-native';
import { ContainerInput, InputType } from './styles';
import { Feather } from '@expo/vector-icons';
import { Theme } from '../../theme/theme';
import { TextInputMask } from 'react-native-masked-text';

interface IInputText {
  type: InputType;
  inputType: KeyboardTypeOptions;
  placeholder: string;
  value?: string;
  width?: string;
  password?: boolean;
  dateTime?: boolean;
  editable?: boolean;
  getText: (text: string) => void;
  iconName: keyof typeof Feather.glyphMap;
}

const InputText: React.FC<IInputText> = ({
  type,
  placeholder,
  inputType,
  iconName,
  password,
  dateTime,
  getText,
  value,
  editable = true,
  width = '100%',
}: IInputText) => {
  const [inputTypeState, setInputTypeState] = useState(type);
  const [inputValue, setInputValue] = useState(value || '');

  const handleChangeText = (text: string) => {
    getText(text);
    setInputValue(text);
  };

  return (
    <ContainerInput type={inputTypeState} style={{ width: width }}>
      <Feather
        name={iconName}
        size={24}
        color={
          inputTypeState === InputType.ERROR
            ? Theme.colors.error
            : inputTypeState === InputType.SUCESS
            ? Theme.colors.sucess
            : inputTypeState === InputType.ACTIVE
            ? Theme.colors.primary
            : Theme.colors.secondary
        }
      />
      {dateTime ? (
        <TextInputMask
          type={'datetime'}
          options={{
            format: 'DD/MM/YYYY',
          }}
          keyboardType={inputType}
          placeholder={placeholder}
          editable={editable}
          value={inputValue}
          onChangeText={handleChangeText}
          secureTextEntry={password || false}
          placeholderTextColor="#000"
          style={{ width: '80%', marginLeft: 10, fontFamily: Theme.fonts.medium }}
          onFocus={() => setInputTypeState(InputType.ACTIVE)}
          onBlur={() => setInputTypeState(InputType.NORMAL)}
        />
      ) : (
        <TextInput
          keyboardType={inputType}
          placeholder={placeholder}
          value={inputValue}
          editable={editable}
          onChangeText={handleChangeText}
          autoCapitalize={'none'}
          secureTextEntry={password || false}
          style={{ width: '80%', marginLeft: 10, fontFamily: Theme.fonts.medium }}
          placeholderTextColor="#000"
          onFocus={() => setInputTypeState(InputType.ACTIVE)}
          onBlur={() => setInputTypeState(InputType.NORMAL)}
        />
      )}
    </ContainerInput>
  );
};

export default InputText;
