import { Feather } from '@expo/vector-icons';
import { useAnimationState } from 'moti';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import { Theme } from '../../theme/theme';
import Button, { typeButton } from '../button';
import BigTitle from '../titles/bigTitle';
import Description from '../titles/description';
import { Container, Content } from './styles';

export enum ETypeModal {
    SUCESS = 'SUCESS',
    ERROR = 'ERROR',
    INFO = 'INFO'
}

interface IModal {
    title: string;
    type: typeButton;
    iconName: keyof typeof Feather.glyphMap;
    description: string;
    show: boolean;
    closeModal: () => void;
}

const Modal: React.FC<IModal> = ({ title, type, iconName, description, show, closeModal }: IModal) => {
    const animationState = useAnimationState({
        closed: {
            opacity: 0,
            top: 1000, // Consider using transform for smoother animations, if applicable
        },
        open: {
            opacity: 1,
            top: 0, // Adjust according to your layout needs
        },
    });

    useEffect(() => {
        if (show === true) {
            animationState.transitionTo('open');
        } else {
            animationState.transitionTo('closed');
        }
    }, [show, animationState]);

    // Only render the Container and its contents if `show` is true
    if (!show) {
        return null;
    }

    return (
        <Container state={animationState}>
            <Content>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Feather name={iconName} size={44} color={type === typeButton.ERROR ? Theme.colors.error : Theme.colors.primary} />
                    <BigTitle text={title} />
                    <Description text={description} />
                </View>
                <Button action={closeModal} loading={false} text="Continuar" type={type}></Button>
            </Content>
        </Container>
    );
}

export default Modal;