import create from 'zustand'
import { DataUser, User } from './model/User'


interface UserState {
    user: DataUser;
    token: string;
    addUser: (user: DataUser) => void;
    addToken: (token: string) => void;
  }
  

export const userAuth = create<UserState>(set => ({
  user: null,
  token:'',
  addUser: (user: DataUser) => {
    set((state) => ({
      user: user,
    }));
  },
  addToken: (token: string) => {
    set((state) => ({
      token: token,
    }));
  },
}))