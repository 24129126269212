import styled from 'styled-components/native';
import { typeButton } from '.';
interface IContainer {
    types: typeButton 
}
export const Container = styled.TouchableOpacity<IContainer>`
  width: 50px; 
  height: 50px;
  background: ${props=>props.types == 'PRIMARY' ? props.theme.colors.seconday : props.types == 'ERROR'  ?props.theme.colors.error :props.theme.colors.background};
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  right: 10px;
`;
