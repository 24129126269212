import styled from 'styled-components/native';

export const Container = styled.View`

`;

interface IMonthNameItem{
    active:boolean
}
export const MonthNameItem = styled.TouchableOpacity<IMonthNameItem>`
    background: ${props => props.theme.colors.background};
    width: 57px;
    justify-content: center;
    margin-left: 10px;
    align-items: center;
    height: 40px;
    border: 1px solid  ${props=>props.active ? '#769dc4' :  props.theme.colors.seconday};
    border-radius: 6px;
`

export const MonthNameLabel = styled.Text<IMonthNameItem>`
     color: ${props=>props.active ? '#769dc4' : props.theme.colors.subTitle};
     font-family: ${props=>props.theme.fonts.medium};
     font-size: ${props=>props.theme.fontSize.description};
`
interface IDayNameItem{
    active:boolean
}
export const DayNameItem = styled.TouchableOpacity<IDayNameItem>`
    width: 50px;
    height: 70px;
    background: ${props => props.theme.colors.background};
    border: 1px solid  ${props=>props.active ? '#769dc4' :  props.theme.colors.seconday};
    flex-direction: column;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
`
export const DayNameLabel = styled.Text<IMonthNameItem>`
     color: ${props=>props.active ? '#769dc4' : props.theme.colors.subTitle};
     font-family: ${props=>props.theme.fonts.medium};
     font-size: ${props=>props.theme.fontSize.description};
`
export const DayNameNumber = styled.Text<IMonthNameItem>`
     color: ${props=>props.active ? '#769dc4' : props.theme.colors.subTitle};
     font-family: ${props=>props.theme.fonts.bold};
     font-size: ${props=>props.theme.fontSize.label};
`
interface IHoursItem{
    active:boolean
}
export const HoursLabel = styled.Text<IMonthNameItem>`
     color: ${props=>props.active ? '#fff' : props.theme.colors.subTitle};
     font-family: ${props=>props.theme.fonts.medium};
     font-size: ${props=>props.theme.fontSize.description};
`
export const HoursItem = styled.TouchableOpacity<IHoursItem>`
    background: ${props=>props.active ? '#769dc4' : props.theme.colors.background};
    border: 1px solid  ${props=>props.active ? '#769dc4' :  props.theme.colors.seconday};
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    width: 59px;
    height: 40px;
`