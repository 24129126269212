import axios from "axios";
import { ResponseApi } from "../../config/responseApi";
import { userAuth } from "../../store/auth";
import api from "../api";



export interface User {
    name: string;
    email: string;
    pass: string;
    nif: string;
    birth: string;
    mobilephone: string;
    userId: string;
    bloked: boolean;
}

export interface ListClinic {
    installationname: string;
    serverRemote: string;
    serverLocal: string;
    InstallationNickname: string;
    portRemote: string;
    active: boolean;
    pacienteId: string;
}

export interface Data {
    user: User;
    listClinic: ListClinic[];
}

export interface RootObject {
    data: Data;
    erro: string;
    token: string;
}

export const LoginApi = async (url:string,email:string,pass:string):Promise<ResponseApi> => {
    try {
        console.log('a');

        const result = await axios.get(`${url}/getall?mobile=true&tipo=loginmobile&email=${email}&pass=${pass}`) 
        const {data,erro,token}= <RootObject>result.data;
        
        const response:ResponseApi ={
            error:erro,
            response:data,
            token:token,
            status:erro === undefined ? 400 : 200,
        } 
        return response;
    } catch (error) {
        
        const response:ResponseApi ={
            response:null,
            status:400,
        } 
        return response;
    }
}

interface IRegister {
    name: string;
    mobilePhone:string;
    email: string;
    birth: string;
    nif: string;
    pass: string;
    app: string;
}
export interface UserR {
    name: string;
    email: string;
    pass: string;
    nif: string;
    birth: string;
    userId: string;
    blocked: boolean;
}
export interface Data {
    userr: UserR;
}
export interface ReponseRegister {
    data: Data;
    error: string;
}

export const RegisterApi = async (url:string,registerInfo:IRegister):Promise<ResponseApi> => {
    try {
        const result = await axios.get(`${url}/getall?mobile=true&tipo=registo&nome=${registerInfo.name}&email=${registerInfo.email}&data=${registerInfo.birth}&nif=${registerInfo.nif}&senha=${registerInfo.pass}`)
        
        

    
        const response:ResponseApi ={
            error:result.data.erro,
            response:null,
            status:result.data.erro ? 400 : 200,
        } 
        
        return response;
    } catch (error) {
        
        const response:ResponseApi ={
            response:null,
            status:400,
        } 
        return response;
    }
}


export const RecoverPassEmailAPIC = async (url:string,email:string,password:string):Promise<ResponseApi> => {
    try {
        const result = await axios.get(`${url}/getall?mobile=true&tipo=recupera&email=${email}&senha=${password}`)
        const response:ResponseApi ={
            error:result.data.erro,
            response:null,
            status:result.data.erro ? 400 : 200,
        } 
        
        return response;
    } catch (error) {
        
        const response:ResponseApi ={
            response:null,
            status:400,
        } 
        return response;
    }
}

export const SaveUserINfo = async (url:string,email:string,data:string,nome:string,id:string):Promise<ResponseApi> => {
    try {
        
        const result = await axios.get(`${url}/getall?mobile=true&tipo=edita&email=${email}&nome=${nome}&data=${data}&nif&id=${id}`)
        const response:ResponseApi ={
            error:result.data.erro,
            response:null,
            status:result.data.erro ? 400 : 200,
        } 
        
        return response;
    } catch (error) {
        
        const response:ResponseApi ={
            response:null,
            status:400,
        } 
        return response;
    }
}


export interface ReponseCodeRegister {
    data: any;
    error: string;
}
export const RegisterCodeApi = async (email:string,code:string):Promise<ResponseApi> => {
    try {
        const result = await api.post('/registarCodigo',{
            email,
            code,
        })
        
        const {data,error}= <ReponseCodeRegister>result.data;
        const response:ResponseApi ={
            error:error,
            response:data,
            status:error ? 400 : 200,
        } 
        return response;
    } catch (error) {
        
        const response:ResponseApi ={
            response:null,
            status:400,
        } 
        return response;
    }
}






