import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { View } from 'react-native';
import { Theme } from '../../theme/theme';
import { FooterItems } from './footerItems';
import { ContainerFooter, FooterItem, FooterLabel } from './styles';

// import { Container } from './styles';

interface IFooter {
    activeIndex:number;
}

const Footer: React.FC<IFooter> = ({activeIndex}:IFooter) => {
  const {navigate} = useNavigation()

  return <ContainerFooter style={{elevation:5}}>
        {FooterItems.map((item,index)=>{
            return (
                <FooterItem key={index} onPress={()=>navigate(item.navigate)}>
                      <Feather name={item.iconName} style={{opacity:activeIndex === index?1:0.4}} size={24} color={Theme.colors.primary} />
                      <FooterLabel active={activeIndex === index} >{item.label}</FooterLabel>
                </FooterItem> 
            )
        })}
       
  </ContainerFooter>;
}

export default Footer;