import { Feather } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useAnimationState } from 'moti';
import React, { useEffect, useState } from 'react';
import {  View } from 'react-native';
import Appointment from '../../../../components/appointment';
import BigTitle from '../../../../components/titles/bigTitle';
import Description from '../../../../components/titles/description';
import { Theme } from '../../../../theme/theme';

import QRCode from 'react-qr-code';
import Button, { typeButton } from '../../../../components/button';
import Header from '../../../../components/header';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { CheckInAppointment } from '../../../../services/tactisService/act';
import { AppAuth } from '../../../../store/app';
import { userAuth } from '../../../../store/auth';
import Modal from '../../../../components/modal';

const BookingDetails: React.FC = () => {
    const {navigate} = useNavigation()
    const [hasPermission, setHasPermission] = useState(null);
    const [scanned, setScanned] = useState(false);
    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);

    const {params} = useRoute()
    const {api,url} = AppAuth()
    const {user,token} = userAuth()
    const jsonQr = {
      consultaId: params.id,
      pacienteId: user.listClinic[0].pacienteId
    };

    useEffect(() => {
      (async () => {
        const { status } = await BarCodeScanner.requestPermissionsAsync();
        setHasPermission(status === 'granted');
      })();
    }, []);


    const handleBarCodeScanned = async ({ type, data }) => {
      setScanned(true);
      
      const jsonData = JSON.parse(data);

      const result = await CheckInAppointment(`https://${user.listClinic[0].serverRemote}:${user.listClinic[0].portRemote}`,jsonData.consultaId,user.listClinic[0].pacienteId,token)
      
      if(result.error){
        setShowError(true)
        return;
      }
      setShow(true)
     
    };
  return   <View style={{flex:1,justifyContent:'flex-start',alignItems:'center', backgroundColor: 'white'}}>
      <Header name={`Detalhes Marcação`} />
  <View style={{marginTop:15}}></View>

  <Appointment id={params?.id} date={params?.date} tipo={params?.tipo}   doctorName={params?.medico} ></Appointment>
  <View style={{marginTop:25}}></View>
  <Description text={'Faça o checkIn com o Qrcode'}/>
      <QRCode value={jsonQr} />

  {/*<BarCodeScanner
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        style={{height:450,width:350,marginTop:20}}
      />
      */}
      <Modal closeModal={()=>setShow(false)} show={show} description={"Check-In efetuado com sucesso"} title='Sucesso' type={typeButton.PRIMARY} iconName='check-square' ></Modal>
      <Modal closeModal={()=>setShowError(false)} show={showError} description={"Não é permitido fazer check-in."} title='Erro' type={typeButton.ERROR} iconName='alert-circle' ></Modal>

</View>;
}

export default BookingDetails;