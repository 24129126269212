import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';
import { View,Text } from 'react-native';
import Button, { typeButton } from '../../../components/button';
import InputText from '../../../components/input';
import { InputType } from '../../../components/input/styles';
import Modal, { ETypeModal } from '../../../components/modal';
import ProgressBar from '../../../components/progressBar';
import Tabs, { TTabItem } from '../../../components/Tabs';
import BigTitle from '../../../components/titles/bigTitle';
import Description from '../../../components/titles/description';
import { DescriptionText } from '../../../components/titles/description/styles';
import { RegisterApi } from '../../../services/apiSrdoutor/auth';
import { RegisterTactis } from '../../../services/tactisService/auth';
import { AppAuth } from '../../../store/app';
import { ContainerRegister } from './styles';

// import { Container } from './styles';

const Register: React.FC = () => {
    const [show,setShow] = useState(false)
    const {api,url} = AppAuth()

    const {navigate }= useNavigation()
    const tabs:TTabItem[] = [
        {
            action:()=>{
                navigate('Login')
            },
            active:false,
            name:'Entrar'
        },
        {
            action:()=>{}, 
            active:true,
            name:'Registar'
        }
    ] 
    const [name,setName] = useState('')
    const [nif,setNif] = useState('')
    const [email,setEmail] = useState('')
    const [birthDay,setBirthDay] = useState('')
    const [password,setPassword] = useState('')
    const [loadingSubmit,setLoadingSubmit] = useState(false)

    
    const [errorMessage,setErrorMessage] = useState('Não foi possivel cria a conta')

    const handleSubmit = async () => {
        setLoadingSubmit(true)
        const [day,month,year] = birthDay.split('/')
        try {
{/*            if(api){
                const result = await RegisterApi(url,{
                    app:'ARTCENTER',
                    birth:`${year}-${month}-${day}`,
                    email,
                    mobilePhone,
                    nif,
                    name,
                    pass:password,
                })
                
                if(result.error === 'Email já se encontra registado, mas não está validado!'){
                    navigate('RegisterPin',{
                        email,
                        password:password,
                    })
                    setLoadingSubmit(false)
                    return;
                }
                if(result.status === 400){
                    setErrorMessage(erro)
                    setShow(true)
                    setLoadingSubmit(false)
                    return; 
                }
                navigate('Home',{
                    email,
                    password:password,
                })
                return
            }
            */}

            // Api TACTIS
            const result = await RegisterTactis({
                app:'ARTCENTER',
                birth:`${year}-${month}-${day}`,
                email,
                nif,
                name,
                pass:password,
            })
            if(result.error === 'Email já se encontra registado, mas não está validado!'){
                navigate('RegisterPin',{
                    email,
                    password:password,
                })
                setLoadingSubmit(false)
                return;
            }
            if(result.status === 400){
                setLoadingSubmit(false)
                console.log(result)
                setErrorMessage(result.error)
                setErrorMessage('erro')
                setShow(true)
                return; 
            }
            navigate('RegisterPin',{
                email,
                password:password,
            })
        } catch (error) {
            setErrorMessage('Não foi possivel cria a conta')
           setShow(true) 
        }
//         setButtonType(typeButton.PRIMARY)
    }
  return<>
   <ContainerRegister>
        <View style={{padding:20}}>
            <Tabs tabs={tabs}></Tabs>
                <View style={{marginTop:0}}/>
        </View>
       
        <View style={{paddingLeft:20,paddingRight:20}}>
          <BigTitle  text={'Criar Conta'}/>
             <InputText getText={(text)=>setName(text)} iconName='user' placeholder='Nome Completo' inputType='default' type={InputType.NORMAL}></InputText>
             <InputText getText={(text)=>setNif(text)}  iconName='clipboard' placeholder='Nif' inputType='phone-pad' type={InputType.NORMAL}></InputText>
             <InputText getText={(text)=>setEmail(text)}  iconName='mail' placeholder='Email' inputType='email-address' type={InputType.NORMAL}></InputText>
             <InputText getText={(text)=>setBirthDay(text)} dateTime iconName='calendar' placeholder='Data Nascimento' inputType='number-pad' type={InputType.NORMAL}></InputText>
             <InputText getText={(text)=>setPassword(text)} password={true} iconName='lock' placeholder='Palavra Passe' inputType='default' type={InputType.NORMAL}></InputText>
        </View>
        <Text style={{fontSize:10,paddingLeft:25,paddingRight:25,marginTop:10}}>
            Ao registar estar a concordar de forma esclarecida e informada, o seu consentimento para efeitos de tratamento de dados pessoais em conformidade com o definido em sede de RGPD.
        </Text>
        <View style={{width:'100%',justifyContent:'center',alignItems:'center',marginTop:10,padding:20}}>
          <Button id="submeter" action={()=>{
              handleSubmit()
          }} loading={loadingSubmit} text="Registar" type={typeButton.PRIMARY}></Button>

       </View>
  </ContainerRegister>
       <Modal closeModal={()=>setShow(false)} show={show} description={errorMessage} title='Erro Conta' type={typeButton.ERROR} iconName='alert-circle' ></Modal>
  </>
}

export default Register;