import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, View, BackHandler } from 'react-native';
import Appointment from '../../../components/appointment';
import ButtonFloat, { typeButton } from '../../../components/buttonFloat';
import EmptyBooking from '../../../components/emptyBooking';
import Footer from '../../../components/footer';
import HeaderUser from '../../../components/headerUser';
import InputText from '../../../components/input';
import { InputType } from '../../../components/input/styles';
import { GetBookingApi } from '../../../services/apiSrdoutor/act';
import { BookingObject, GetBooking } from '../../../services/tactisService/act';
import { AppAuth } from '../../../store/app';
import { userAuth } from '../../../store/auth';
import { ContainerHome } from './styles';

// import { Container } from './styles';

const Home: React.FC = () => {
  const {user,token} = userAuth()
  const {navigate} = useNavigation()
  const {api,url} = AppAuth()

  const [bookings,setBokings] = useState<BookingObject[]>([])
  const [bookingsAll,setBokingsAll] = useState<BookingObject[]>([])

  const [filterText,setFilterText] = useState('')

  const handleGetBooking = async ()=> {
    if(api){
      const result = await GetBookingApi(url,String(user.listClinic[0].pacienteId),'')
      setBokingsAll(result)
      setBokings(result)
      return;
    }
    const result = await GetBooking(`https://${user.listClinic[0].serverRemote}:${user.listClinic[0].portRemote}`,user.listClinic[0].pacienteId,token)
    if(result ===401){
        navigate('Login')
    }
    setBokingsAll(result)
    setBokings(result)
  }

  useEffect(() => {
          const backAction = () => {
            // Perform any custom logic you want before preventing the default back button behavior

            // Prevent default back button behavior
            return true;
          };

          const backHandler = BackHandler.addEventListener(
            'hardwareBackPress',
            backAction
          );
          // Cleanup the event listener on component unmount
          return () => backHandler.remove();
        },
  []);

  useEffect(()=>{
    if(!filterText){
      handleGetBooking()
    }else{
      const bookingsFilter =[...bookingsAll];
      setBokings(bookingsFilter.filter(x=>x.especialidade.toUpperCase().includes(filterText.toUpperCase()) || x.medico.toUpperCase().includes(filterText.toUpperCase())))
    }
  },[filterText])
  return <React.Fragment>
   <ContainerHome>
    <HeaderUser></HeaderUser>
    <InputText getText={(text)=>setFilterText(text)} type={InputType.NORMAL} password={false} placeholder="Pesquisar" inputType='default' iconName='search'  />
    <View style={{marginBottom:20}}></View>

    {bookings.length > 0  ?
    <ScrollView showsVerticalScrollIndicator>

       { bookings.filter(x=>x.status.id === 1).map(booking=>{
          return (
            <React.Fragment key={booking.id}> {/* Add the unique key prop here */}

                <TouchableOpacity onPress={()=>  navigate('BookingDetails',{
                  id:booking.id,
                  medico:booking.medico,
                  tipo:booking.especialidade,
                  date:booking.dataInicio,
                })}>
                <Appointment id={booking?.id} date={booking?.dataInicio} tipo={booking.especialidade}   doctorName={booking.medico} ></Appointment>
                </TouchableOpacity>

                <View style={{marginBottom:20}}></View>
            </React.Fragment>


          )
        })}
    </ScrollView>

    :<EmptyBooking></EmptyBooking> }
    <ButtonFloat action={()=>navigate('BookingCreate')} iconName='plus' type={typeButton.PRIMARY}></ButtonFloat>
  </ContainerHome>
  <Footer activeIndex={0}></Footer>
  </React.Fragment>
}

export default Home;