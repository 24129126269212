import styled from 'styled-components/native';

export const ContainerHome = styled.View`
  background-color: ${props => props.theme.colors.background};
  padding: 20px;
  height: 100%;
  flex: 1;
  padding-top: 10px;
  width: 100%;
`;
