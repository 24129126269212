import styled from 'styled-components/native';

export const ContainerLogin = styled.View`
  background-color: ${props => props.theme.colors.background};
  padding: 20px;
  height: 100%;
  padding-top: 30px;
`;

export const ContainerIos = styled.View`
    flex: 1;
    justify-content: center;
    align-items: justify;
    background-color: ${props => props.theme.colors.primary};
    padding: 20px;
    margin: 10px;
    min-height:140px;
`;

export const Commands = styled.Text`
 flex: 1;
text-align: left;
    color: #000;
    margin-bottom: 5px;
`;