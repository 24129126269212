import React, { useRef, useState, useEffect } from 'react';
import { TextInput, View, BackHandler } from 'react-native';
import Button, { typeButton } from '../../../components/button';
import InputText from '../../../components/input';
import { InputType } from '../../../components/input/styles';
import Modal, { ETypeModal } from '../../../components/modal';
import ProgressBar from '../../../components/progressBar';
import Tabs, { TTabItem } from '../../../components/Tabs';
import BigTitle from '../../../components/titles/bigTitle';
import Description from '../../../components/titles/description';
import { DescriptionText } from '../../../components/titles/description/styles';
import {  LoginTactis, PutConsentimento, RegisterCodeTactis, RegisterTactis } from '../../../services/tactisService/auth';
import { ContainerRegister, InputCode } from './styles';
import LottieView from 'lottie-react-native';
import { Theme } from '../../../theme/theme';
import { useNavigation, useRoute } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { userAuth } from '../../../store/auth';

// import { Container } from './styles';

const RegisterPin: React.FC = () => {
    const [show,setShow] = useState(false)
    const {navigate} = useNavigation()
    const {params}= useRoute()
    const input2ref = useRef<TextInput>(null)
    const input3ref = useRef(null)
    const input4ref = useRef(null)
    const input5ref = useRef(null)
    const input6ref = useRef(null)
    const input7ref = useRef(null)
    const input8ref = useRef(null)
    const {addUser,addToken} = userAuth()
    const [input1,setInput1] = useState('')
    const [input2,setInput2] = useState('')
    const [input3,setInput3] = useState('')
    const [input4,setInput4] = useState('')
    const [input5,setInput5] = useState('')
    const [input6,setInput6] = useState('')
    const [input7,setInput7] = useState('')
    const [input8,setInput8] = useState('')
    const [errorMessage,setErrorMessage] = useState('Não foi possivel cria a conta')
    const [loadingSubmit,setLoadingSubmit] = useState(false)

    useEffect(() => {
        const backAction = () => {
          // Perform any custom logic you want before preventing the default back button behavior

          // Prevent default back button behavior
          return true;
        };

        const backHandler = BackHandler.addEventListener(
          'hardwareBackPress',
          backAction
        );
        // Cleanup the event listener on component unmount
        return () => backHandler.remove();
      },
    []);

    const handleSubmit = async () => {
        try {
            setLoadingSubmit(true)
            const result = await RegisterCodeTactis(params?.email,`${input1}${input2}${input3}${input4}${input5}${input6}${input7}${input8}`)
            if(result.status === 400){
                setErrorMessage(result.error)
                setLoadingSubmit(false)
                setShow(true)
                return; 
            }
            const resultLogin = await LoginTactis(params?.email,params?.password)
            
            if(resultLogin.status === 400){
                setLoadingSubmit(false)
                setErrorMessage(resultLogin.error)
                setShow(true)
                return; 
            }
            addUser(resultLogin.response)
            addToken(resultLogin.token)
            await AsyncStorage.setItem('@user',JSON.stringify(resultLogin.response))
            await AsyncStorage.setItem('@token',JSON.stringify(resultLogin.token))
            
            await PutConsentimento(resultLogin.token,resultLogin.response.listClinic[0].pacienteId,`https://${resultLogin.response.listClinic[0].serverRemote}:${resultLogin.response.listClinic[0].portRemote}`,true)

            navigate('Home')
        } catch (error) {
            
            setErrorMessage('Não foi possivel cria a conta')
           setShow(true) 
        }
        
    }
  
  return<>
   <ContainerRegister>
        <View style={{paddingLeft:20,paddingRight:20}}>
          <BigTitle  text={'Insira o Codigo'}/>
          <View style={{flexDirection:'row',width:'100%',flexWrap:'wrap',justifyContent:'center'}}>
{/*           <LottieView */}
{/*             autoPlay */}
{/*             style={{ */}
{/*                 width: 300, */}
{/*                 height: 300, */}
{/*             }} */}
{/*             source={require('../../../assets/animations/emailSend.json')} /> */}
          </View>
       
          <Description text={'Foi enviado para o email de registo um código de validação. Insira aqui'} />
        </View>
        <View style={{flexDirection:'row',width:'100%',flexWrap:'wrap',justifyContent:'center'}}>
            <InputCode maxLength={1} keyboardType='number-pad' onChangeText={(text)=>{ 
                if(text.length === 1){
                    setInput1(text)
                    input2ref.current.focus();
                }
            }}  
            style={{fontFamily:Theme.fonts.medium,justifyContent:'center',textAlign:'center',fontSize:24}} 
            type={InputType.NORMAL}></InputCode>
            <InputCode maxLength={1} ref={input2ref} keyboardType='number-pad' onChangeText={(text)=>{ 
                if(text.length === 1){
                    setInput2(text)
                    input3ref.current.focus();
                }
            }}  
            style={{fontFamily:Theme.fonts.medium,justifyContent:'center',textAlign:'center',fontSize:24}}  type={InputType.NORMAL}></InputCode>
          <InputCode maxLength={1} ref={input3ref} keyboardType='number-pad' onChangeText={(text)=>{ 
                if(text.length === 1){
                    setInput3(text)
                    input4ref.current.focus();
                }
            }}  
            style={{fontFamily:Theme.fonts.medium,justifyContent:'center',textAlign:'center',fontSize:24}}  type={InputType.NORMAL}></InputCode>
            <InputCode maxLength={1} ref={input4ref} keyboardType='number-pad' onChangeText={(text)=>{ 
                if(text.length === 1){
                    setInput4(text)
                    input5ref.current.focus();
                }
            }}  
            style={{fontFamily:Theme.fonts.medium,justifyContent:'center',textAlign:'center',fontSize:24}}  type={InputType.NORMAL}></InputCode>
   <InputCode maxLength={1} ref={input5ref} keyboardType='number-pad' onChangeText={(text)=>{ 
                if(text.length === 1){
                    setInput5(text)
                    input6ref.current.focus();
                }
            }}  
            style={{fontFamily:Theme.fonts.medium,justifyContent:'center',textAlign:'center',fontSize:24}}  type={InputType.NORMAL}></InputCode>
            <InputCode maxLength={1} ref={input6ref} keyboardType='number-pad' onChangeText={(text)=>{ 
                if(text.length === 1){
                    setInput6(text)
                    input7ref.current.focus();
                }
            }}  
            style={{fontFamily:Theme.fonts.medium,justifyContent:'center',textAlign:'center',fontSize:24}}  type={InputType.NORMAL}></InputCode>
 <InputCode maxLength={1} ref={input7ref} keyboardType='number-pad' onChangeText={(text)=>{ 
                if(text.length === 1){
                    setInput7(text)
                    input8ref.current.focus();
                }
            }}  
            style={{fontFamily:Theme.fonts.medium,justifyContent:'center',textAlign:'center',fontSize:24}}  type={InputType.NORMAL}></InputCode>
            <InputCode maxLength={1} ref={input8ref} keyboardType='number-pad' onChangeText={(text)=>{ 
                if(text.length === 1){
                    setInput8(text)
                }
            }}  
            style={{fontFamily:Theme.fonts.medium,justifyContent:'center',textAlign:'center',fontSize:24}}  type={InputType.NORMAL}></InputCode>
        </View>
        <View style={{width:'100%',justifyContent:'center',alignItems:'center',marginTop:20,padding:20}}>
          <Button action={()=>handleSubmit()} loading={loadingSubmit} text="Validar" type={typeButton.PRIMARY}></Button>
       </View>
  </ContainerRegister>
       <Modal closeModal={()=>setShow(false)} show={show} description={errorMessage} title='Erro Conta' type={typeButton.PRIMARY} iconName='alert-circle' ></Modal>
  </>
}

export default RegisterPin;