import * as React from 'react';
import { ActivityIndicator, View } from 'react-native';
import ButtonLabel from '../titles/buttonLabel';
import { Container } from './styles';

// import { Container } from './styles';

export enum typeButton { PRIMARY = 'PRIMARY',SECONDARY='SECONDARY',ERROR = 'ERROR', DISABLED = 'DISABLED'}
interface IButton {
   type:typeButton,
   action:()=> void;
   loading:boolean;
   text:string;
   marginBottom:int;
}

const Button: React.FC<IButton> = ({action,type,text,loading,marginBottom = 0}:IButton) => {
  return <Container style={{marginBottom:marginBottom}} types={type} onPress={action}>
      {loading ? <ActivityIndicator color={'#fff'} size={20}></ActivityIndicator> :  <ButtonLabel typeButton={type} text={text}></ButtonLabel> }
  </Container>;
}



export default Button;