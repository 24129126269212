import styled from 'styled-components/native';

export const ContainerRegister= styled.ScrollView`
  background-color: ${props => props.theme.colors.background};
  height: 100%;
`;


export enum InputType {
  ERROR = 'ERROR',
  SUCESS = 'SUCESS',
  ACTIVE = 'ACTIVE',
  NORMAL = 'NORMAL'
}
interface IContainerInput {
  type:InputType
}

export const InputCode = styled.TextInput<IContainerInput>`
background-color:${props=>props.theme.colors.background};
border: 1px solid ${props=>props.type ==InputType.ACTIVE ? 
 props.theme.colors.primary : props.type ==InputType.ERROR ? 
 props.theme.colors.error : props.type ==InputType.SUCESS ? 
 props.theme.colors.sucess  :  props.theme.colors.seconday};
align-items: center;
width: 50px;
margin-top: 10px;
margin-left: 5px;
border-radius: 6px;
height: 50px;
`;
