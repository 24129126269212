import axios from "axios";
import { ResponseApi } from "../../config/responseApi";
import { userAuth } from "../../store/auth";
import api from "../api";
import { NativeModules } from 'react-native';




export interface User {
    name: string;
    email: string;
    pass: string;
    nif: string;
    birth: string;
    mobilePhone: string;
    userId: string;
    blocked: boolean;
}

export interface ListClinic {
    installationName: string;
    serverRemote: string;
    serverLocal: string;
    installationNickname: string;
    portRemote: number;
    active: boolean;
    pacienteId: number;
}

export interface Datalogin {
    user: User;
    listClinic: ListClinic[];
}

export interface ResponseLogin {
    data: Datalogin;
    error: string;
    token:string
}

export const LoginTactis = async (email:string,pass:string):Promise<ResponseApi> => {
    try {
        const result = await api.post('/login',{
            email,
            pass,
            app:"ARTCENTER"
        })
        const {data,error,token}= <ResponseLogin>result.data;

        const response:ResponseApi ={
            error:error,
            response:data,
            token:token,
            status:error ? 400 : 200,
        }
        return response;
    } catch (error) {

        const response:ResponseApi ={
            response:null,
            status:400,
        }
        return response;
    }
}

export const ValidateToken = async (tok:string):Promise<ResponseApi> => {
    try {
        axios.defaults.httpsAgent = NativeModules.networkModule;
        const urls = 'https://tactiscloud01.ddns.net:39858/checkToken';
        const headers = {
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'Connection': 'keep-alive',
          'Accept-Encoding': 'gzip, deflate, br'
        };
        const charToRemove = '"';
        const requestData = {
          token: tok.replace(new RegExp(charToRemove, 'g'), '')
        };
        const result = await api.post(urls, requestData, { headers })
//         res = JSON.parse(result);
//         const isValid = parsedResponse.data.isValid;

        return result.data.isValid;
    } catch (error) {

        const response:ResponseApi ={
            response:'erro',
            status:400,
        }
        return false;
    }
}

interface IRegister {
    name: string;
    email: string;
    birth: string;
    nif: string;
    pass: string;
    app: string;
}
export interface UserR {
    name: string;
    email: string;
    pass: string;
    nif: string;
    birth: string;
    userId: string;
    blocked: boolean;
}
export interface Data {
    userr: UserR;
}
export interface ReponseRegister {
    data: Data;
    error: string;
}

export const RegisterTactis = async (registerInfo:IRegister):Promise<ResponseApi> => {
    try {
        const result = await api.post('/registarUtilizador',registerInfo)
        const {data,error}= <ReponseRegister>result.data;
        const response:ResponseApi ={
            error:error,
            response:data.userr,
            status:error ? 400 : 200,
        }

        return response;
    } catch (error) {

        const response:ResponseApi ={
            response:null,
            status:400,
        }
        return response;
    }
}


export interface ReponseCodeRegister {
    data: any;
    error: string;
}
export const RegisterCodeTactis = async (email:string,code:string):Promise<ResponseApi> => {
    try {
        const result = await api.post('/registarCodigo',{
            email,
            code,
            "app": "ARTCENTER"
        })

        const {data,error}= <ReponseCodeRegister>result.data;
        const response:ResponseApi ={
            error:error,
            response:data,
            status:error ? 400 : 200,
        }
        return response;
    } catch (error) {

        const response:ResponseApi ={
            response:null,
            status:400,
        }
        return response;
    }
}

export const RecoveryPasswordUserEmail = async (email:string):Promise<ResponseApi> => {
    try {
        const result = await api.post(`/recuperarUtilizador?email=${email}`,{
            "app": "ARTCENTER"
        })

        const {data,error}= <ReponseCodeRegister>result.data;
        const response:ResponseApi ={
            error:error,
            response:data,
            status:error ? 400 : 200,
        }
        return response;
    } catch (error) {

        const response:ResponseApi ={
            response:null,
            status:400,
        }
        return response;
    }
}
export const SaveUserInfo = async (token:string,id:string,phone:string,address:string,city:string,postcode1:string,postcode2:string,userData:string,):Promise<ResponseApi> => {
    try {
        let ur = `fichabiografica?pacienteId=${id}`;
        let options = {
            method: 'PUT',
            url: ur,
            params: {},
            data:{"id": id,"email": userData.email,"sexo": userData.sexo,"birth": "1979-09-30","bi": userData.bi,
            "nif": userData.nif,"sns": userData.sns,"estadoCivil": userData.estadoCivil,"localidade":city,"morada": address,
            "codPost1":postcode1,"codPost2":postcode2,"telemovel":phone},
            headers: {
              'NPAC-API-TOKEN': token
            }
        };
        const result = await api.request(options);
        const responseData = result.data;
        const response:ResponseApi ={
            error:result.data.erro,
            response:null,
            status:result.data.erro ? 400 : 200,
        }
        return response;
    } catch (error) {

        const response:ResponseApi ={
            response: null,
            status:400,
        }
        return response;
    }
}

export const GetUserInfo = async (token:string,id:string):Promise<ResponseApi> => {
    try {
        let ur = `/fichabiografica?pacienteId=${id}`;
        let options = {
            method: 'GET',
            url: ur,
            params: {},
            headers: {
              'NPAC-API-TOKEN': token
            }
        };
        const result = await api.request(options);
        const responseData = result.data;
        return responseData
    } catch (error) {

        const response:ResponseApi ={
            response: null,
            status:400,
        }
        return response;
    }
}

export const RecoveryPasswordCodeEmail = async (email:string,code:string,password:string):Promise<ResponseApi> => {
    try {
        const result = await api.post(`/recuperarPass`,{
            email,
            code,
            "app": "ARTCENTER",
            password,
        })
        const {data,error}= <ReponseCodeRegister>result.data;
        const response:ResponseApi ={
            error:error,
            response:data,
            status:error ? 400 : 200,
        }
        return response;
    } catch (error) {

        const response:ResponseApi ={
            response:null,
            status:400,
        }
        return response;
    }
}






export const GetConsentimento = async (token:string,pacienteId:string,url:string):Promise<boolean> => {
    try {
        const result = await axios.get(`${url}/consentimento/app?pacienteId=${pacienteId}`,{
            headers:{
              'NPAC-API-TOKEN': token
            }
          })
        const {appConsent}= result.data;
        
        

        return appConsent;
    } catch (error) {
        
        const response:ResponseApi ={
            response:null,
            status:400,
        } 
        return null;
    }
}



export const PutConsentimento = async (token:string,pacienteId:string,url:string,accept:boolean):Promise<boolean> => {
        try {
            var data = {
                "appConsent": accept
              };
           var options = {
            method: 'PUT',
            url: `${url}/consentimento/app?pacienteId=${pacienteId}`,
            params: {},
            data:data,
            headers: {
              'npac-api-token': token
            }
          };
          
          
          const result = await axios.request(options)
          
            return result.data
        } catch (error) {
          
      
         return null
        }
}



