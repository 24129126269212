import Feather from '@expo/vector-icons/build/Feather';
import * as React from 'react';
import { View } from 'react-native';
import { Theme } from '../../theme/theme';
import ButtonLabel from '../titles/buttonLabel';
import { Container } from './styles';

// import { Container } from './styles';

export enum typeButton { PRIMARY = 'PRIMARY',SECONDARY='SECONDARY',ERROR = 'ERROR'}
interface IButtonFloat {
   type:typeButton,
   action:()=> void;
   loading:boolean;
   text:string;
   iconName: keyof typeof Feather.glyphMap
}

const ButtonFloat: React.FC<IButtonFloat> = ({action,type,text,iconName}:IButtonFloat) => {
  return <Container types={type} onPress={action}>
         <Feather name={iconName} size={28} color={Theme.colors.primary} />
  </Container>;
}



export default ButtonFloat;