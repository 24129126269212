import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { Pressable, View } from 'react-native';
import BigTitle from '../titles/bigTitle';
import SubTitle from '../titles/subTitle';
import { ContainerHeader, TitleHeader } from './styles';

// import { Container } from './styles';

interface IHeader {
    name:string
}
const Header: React.FC<IHeader> = ({name}:IHeader) => {
  const {goBack} = useNavigation()
  return <ContainerHeader>
         <Pressable style={{marginRight:15}} onPress={()=>goBack()}>
                <Feather name={'arrow-left-circle'} size={32} color={'#000'} />
         </Pressable>
          <TitleHeader>
              {name}
          </TitleHeader>
  </ContainerHeader>;
}

export default Header;