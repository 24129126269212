import * as React from 'react';
import { Pressable, View } from 'react-native';
import { typeButton } from '../button';
import WhiteLabel from '../titles/buttonLabel';
import { Container, TabItem } from './styles';

// import { Container } from './styles';

export type TTabItem = {
    name:string,
    action:()=>void,
    active:boolean,
}

interface ITabs {
    tabs:TTabItem[]
}

const Tabs: React.FC<ITabs> = ({tabs}:ITabs) => {
  return <Container>
      {tabs.map(tab => {
          return (
              <Pressable key={tab.name} onPress={tab.action}>
                 <TabItem active={tab.active}>
                     <WhiteLabel text={tab.name} typeButton={tab.active ? typeButton.PRIMARY  : typeButton.SECONDARY}></WhiteLabel>
                 </TabItem>
              </Pressable>

          )
      })}
  </Container>;
}

export default Tabs;