import styled from 'styled-components/native';
import { typeButton } from '.';
interface IContainer {
    types: typeButton 
}
export const Container = styled.TouchableOpacity<IContainer>`
  width: 100%; 
  height: 50px;
  background: ${props=>props.types == 'PRIMARY' ? props.theme.colors.seconday : props.types == 'ERROR' ? props.theme.colors.error :  props.types == 'DISABLED' ? props.theme.colors.disabled : props.theme.colors.background};
  border-radius: 6px;
  justify-content: center;
  align-items: center;
`;
