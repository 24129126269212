import axios from "axios";
import { ResponseApi } from "../../config/responseApi";
import { userAuth } from "../../store/auth";
import { Acts } from "../../store/model/Acts";
import { Vacancies } from "../../store/model/Vacancies";

export const GetActsApi = async (url:string):Promise<Acts[]> => {
    try {
        const result = await axios.get(`${url}/getall?mobile=true&tipo=atos`)
        const acts:Acts[] = result.data
        return acts
    } catch (error) {
      
        const response:ResponseApi ={
            response:null,
            status:400,
        } 
    }
}
export const GetVacaniesAPi = async (url:string,userId:string,token:string,doctorId:string,date:string):Promise<Vacancies[]> => {
  try {
      const result = await axios.get(`${url}/getall?mobile=true&tipo=vagas&pacienteId=${userId}&medicoid=${doctorId}&data=${date}`)
      const vacancies:Vacancies[] = result.data
      return vacancies
  } catch (error) {
    
   
      const response:ResponseApi ={
          response:null,
          status:400,
      } 
  }
}


export interface BookingI {
  id: number;
  dataInicio: string;
  duracao: number;
  medico: string;
  especialidade: string;
  anotacao: string;
  entidade: string;
  servico: string;
}

export const CreateBookingApi = async (url:string,userId:string,token:string,doctorId:string,date:string):Promise<BookingI> => {
  try {
    const result = await axios.get(`${url}/getall?mobile=true&tipo=marcaconsultas&pacienteid=${userId}&medicoid=${doctorId}&dataInicio=${date}`)
    
    return result.data
  } catch (error) {
   return null
  }
}


export interface Status {
  id: number;
  num: number;
  nome: string;
  cor: string;
  titulo: string;
}

export interface BookingObject {
  id: number;
  dataInicio: string;
  status: Status;
  duracao: number;
  medicoId: string;
  medico: string;
  especialidade: string;
  anotacao: string;
  entidade: string;
  servico: string;
}

export const GetBookingApi = async (url:string,userId:string,token:string):Promise<BookingObject[]> => {
  try {
      const result = await axios.get(`${url}/getall?mobile=true&tipo=consultas&pacienteid=${userId}`)
      const bookingObject:BookingObject[] = result.data
      return bookingObject
  } catch (error) {
    
      const response:ResponseApi ={
          response:null,
          status:400,
      } 
  }
}

export const GetBookingHistoryApi = async (url:string,userId:string,token:string):Promise<BookingObject[]> => {
  try {
      const result = await axios.get(`${url}/getall?mobile=true&tipo=historico&pacienteid=${userId}`)
      const bookingObject:BookingObject[] = result.data
      return bookingObject
  } catch (error) {
    
      const response:ResponseApi ={
          response:null,
          status:400,
      } 
  }
}

export interface RootObject {
  id: number;
  classe: string;
  benef: string;
  datadoc: string;
  docref: string;
  mp: string;
  total: number;
}

export const GetContaCorrenteApi = async (url:string,userId:string,token:string):Promise<RootObject[]> => {
  try {
      const result = await axios.get(`${url}/getall?mobile=true&tipo=contacorrente&pacienteid=${userId}`)
      const bookingObject:RootObject[] = result.data
      return bookingObject
  } catch (error) {
    
      const response:ResponseApi ={
          response:null,
          status:400,
      } 
  }
}


